/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ConfirmFormModal, LoanStage } from "@toorak/tc-common-fe-sdk";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { InfoIcon } from "../../icons/info";
import { getExcelDataInSession, isLATUser } from "../../utils/AccessManagement";
import { postRateLock } from "../create-loan.action";
import { setLoanByIdSuccess } from "../create-loan.reducer";
import { formatFesLoanDetailsForRedux } from "../../stores/FrontEndSizer/fesLoanCreation.action";
import { MergeRecursive } from "../../utils/formatChecks";
import { showPopUpMessages } from "../../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import {
  FESRateLockMessageHeader,
  FESRateLockPoupBody,
  MessageBody,
  MessageHeader
} from "./InfoPopUp";
import { ObjectType } from "../../masterView/common";
import { WaiverWrapper } from "../guidelines-and-pricing-comparision/PricingAndGuidlinesSectionModal/WaiverWrapper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducer";
import { getWaiverList } from "../../stores/EvaluationResults/EvaluationResults.action";
import { TKOPModalConatiner } from "../../take-out-partners/TKOPModalContainer";
import { ToggleGroup } from "../../ui-components/ToggleGroup";
import FailedIcon from "../../images/createloan/FailedTestIconPink.svg";
import { BuyDownOptionsWithPrevValues } from "../../ui-components/BuyDownOptionsWithPrevValues";
import { RateLockSelection } from "./RateLockSelection";

export interface loanDataInterface {
  // loanStageForWaiver: LoanStage;
  loanIdForWaiver: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRowGrid: {
      "& .MuiTableCell-root.MuiTableCell-head": {
        padding: "16px"
      },
      "& .MuiTableCell-root": {
        padding: "16px 16px",
        border: "0px"
      }
    },
    headerRowBackground: {
      background: "white",
      borderBottom: "1px solid #f2f2f2"
    },
    headerButton: {
      padding: "0.4rem",
      backgroundColor: "white",
      borderRadius: "25px"
    },
    button: {
      fontSize: "12px",
      fontWeight: 400,
      height: "35px",
      borderRadius: "25px !important",
      padding: "10px 20px",
      letterSpacing: "0.5px",
      color: "#000 !important",
      border: "none",
      "&.$Mui-selected": {
        backgroundColor: "#5e72e4 !important",
        fontWeight: "600 !important",
        color: "#fff !important",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
      },
      "&:disabled": {
        color: "#32325d",
        opacity: 0.5,
        fontWeight: 600,
        cursor: "no-drop",
        borderColor: "#d8d5d5 !important"
      },
      textTransform: "none"
    }
  })
);
const views = [
  { label: "Pricing", value: "pricing" },
  { label: "Test Result", value: "guidelineTests" }
];

const LockInselectionForMultiLoan = (props: any) => {
  const {
    loansArray,
    currentSelectedPartner,
    loanId,
    takeOutPartnerList,
    requestLockApproval,
    criticalTests,
    programSelectConfig,
    setShowWaiverPage,
    showWaiverPage,
    handleBack,
    setGlobalSelected,
    selectedProgramDetails,
    dropDownLoanOptions,
    setDropDownLoanOptions,
    waiversCreated,
    selectedLoan,
    setSelectedLoan,
    globalFilteredPartners,
    setGlobalFilteredPartners,
    loanStage,
    isSubmitDisabled,
    eligibleTakeOutPartnerResult,
    showRateLockSelection,
    showBuyDownOptions = false,
    ...rateLockSelectionProps
  } = props;
  const classes = useStyles();

  const excelData = sessionStorage.getItem("excelUpload");

  const [selectedView, setSelectedView] = useState("pricing");
  const [firstTimeOnly, setFirstTimeOnly] = useState<boolean>(true);
  const [waiverDetails, setWaiverDetails] = useState<boolean>(false);
  const loansDropdownArray = useMemo(() => {
    return loansArray.map((it: ObjectType) => ({
      ...it,
      value: it.toorakLoanId,
      label: `${it.originatorLoanId}/${it.toorakLoanId}`
    }));
  }, [loansArray]);

  const loansMap: Map<string, ObjectType> = useMemo(() => {
    return new Map(loansArray.map((it: ObjectType) => [it.toorakLoanId, it]));
  }, [loansArray]);

  const handleToggle = (e: any, value: string) => {
    setSelectedView(value);
  };

  const handleLoanIdChange = (e: any) => {
    const selected: any = loansMap.get(e?.target?.value);
    setSelectedLoan(selected?.toorakLoanId);
  };

  const handleRequestWaiverClick = (waiverDetails: any) => {
    setShowWaiverPage(true);
    setWaiverDetails({ ...waiverDetails });
  };

  useEffect(() => {
    if (!(excelData && globalFilteredPartners.length)) return;
    if (selectedLoan && Object.keys(selectedLoan).length === 0) return;
    const selectedLoanNew = { ...selectedLoan };
    selectedLoanNew.filteredPartners = globalFilteredPartners;
    setSelectedLoan(selectedLoanNew);
    const dropDownLoanOptionsNew = JSON.parse(
      JSON.stringify(dropDownLoanOptions)
    );
    const index = dropDownLoanOptions.findIndex(
      (obj: { value: string }) => obj.value === selectedLoan.value
    );
    if (index !== -1) {
      dropDownLoanOptionsNew[index].filteredPartners = globalFilteredPartners;
    }
    setDropDownLoanOptions(dropDownLoanOptionsNew);

    // eslint-disable-next-line
  }, [globalFilteredPartners]);

  useEffect(() => {
    const selectedLoanNew = { ...selectedLoan };
    selectedLoanNew.waiverDetails = waiversCreated;
    selectedLoanNew.selectedProgramDetails = selectedProgramDetails;
    setSelectedLoan(selectedLoanNew);
    const dropDownLoanOptionsNew = [...dropDownLoanOptions];
    const index = dropDownLoanOptionsNew.findIndex(
      (obj) => obj.value === selectedLoan.value
    );
    if (index !== -1) {
      dropDownLoanOptionsNew[index].waiverDetails = waiversCreated;
      dropDownLoanOptionsNew[index] = selectedLoanNew;
    }
    setDropDownLoanOptions(dropDownLoanOptionsNew);
    if (firstTimeOnly) {
      setSelectedLoan(dropDownLoanOptionsNew[0]);
      setFirstTimeOnly(false);
    }
    // eslint-disable-next-line
  }, [waiversCreated, selectedProgramDetails]);

  // const currentValues = {
  //   interestRate: 6.875,
  //   discountPoints: 0.1250,
  //   discountPointsDollar: 250.00,
  // };

  // const requestedValues = {
  //   interestRate: 6.875,
  //   discountPoints: 0.1000,
  //   discountPointsDollar: 200.00,
  // };

  return (
    <>
      {showWaiverPage && (
        <div
          style={{
            borderTop: "1px solid lightgrey",
            marginTop: "-20px",
            width: "80vw"
          }}
        >
          <WaiverWrapper
            selectedLoan={selectedLoan}
            waiverDetails={waiverDetails}
            selectedProgramDetails={{ key: currentSelectedPartner?.partnerId }}
            callback={handleBack}
          />
        </div>
      )}
      <div style={{ display: showWaiverPage ? "none" : "inherit" }}>
        {showRateLockSelection && (
          <RateLockSelection
            {...rateLockSelectionProps}
            classes={classes}
            loansArray={loansArray}
          />
        )}
        {!showBuyDownOptions && (
          <>
            {" "}
            <Grid
              container
              style={{
                display: "flex",
                width: "100%",
                background: "#eef6ff",
                padding: "8px",
                border: "1px solid #dedede"
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  paddingLeft: "8px"
                }}
              >
                <Typography>Select Program</Typography>
                <span
                  style={{
                    alignSelf: "end",
                    fontSize: "1.1rem",
                    paddingLeft: "4px"
                  }}
                >
                  <InfoIcon />
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ToggleGroup
                  toggleOptions={views}
                  handleChange={handleToggle}
                  alignment={selectedView}
                  classes={classes}
                />
              </Grid>
            </Grid>
            <TKOPModalConatiner
              onClose={() => {}}
              loanId={loanId}
              loanStage={loanStage}
              partners={takeOutPartnerList}
              takeoutPartnerId={currentSelectedPartner.partnerId}
              allowPartnerChange={true}
              rateLockData={{ rateLockPeriodAndExpiry: null }}
              programSelectConfig={{
                ...programSelectConfig,
                onRequestWaiverClick: handleRequestWaiverClick,
                setGlobalSelected: setGlobalSelected,
                setGlobalFilteredPartners: setGlobalFilteredPartners,
                selectedProgramDetails: selectedProgramDetails,
                isProgramSelectView: true,
                criticalTests: criticalTests,
                loansDropdownArray: loansDropdownArray,
                handleLoanIdChange: handleLoanIdChange,
                showPricingOnlyForEligible: isLATUser() ? false : true
              }}
              showSelector={false}
              selectedViewProp={selectedView}
            />
          </>
        )}
        {requestLockApproval &&
          eligibleTakeOutPartnerResult?.eligiblePartners.includes(
            currentSelectedPartner?.partnerId
          ) && (
            <div
              style={{
                display: "flex",
                margin: "1rem 2rem",
                background: "rgb(238, 246, 255)",
                padding: "1rem"
              }}
            >
              <span style={{ paddingRight: "8px" }}>
                <InfoIcon />
              </span>
              <span>
                The Toorak purchase price of the loan is determined by the most
                up-to-date rate sheet posted to Toorak Connect at the time the
                loan is locked. The lock date will be the date the loan tape or
                loan data and minimum documentation files are initially uploaded
                to Toorak Connect (this includes pre-close loan submissions). If
                the minimum documentation files are not uploaded to Toorak
                Connect at the time the loan is locked, the loan may be placed
                on “hold” however, lock period will continue to be engaged.
                Toorak must close/buy the loan within the lock period. The
                minimum document checklist can be found in our document
                repository.
              </span>
            </div>
          )}
        {isSubmitDisabled &&
          !eligibleTakeOutPartnerResult?.eligiblePartners.includes(
            currentSelectedPartner?.partnerId
          ) &&
          selectedView === "pricing" &&
          !showBuyDownOptions && (
            <div
              style={{
                display: "flex",
                margin: "1rem 2rem",
                background: "#ffe5ea",
                padding: "1rem"
              }}
            >
              <span style={{ paddingRight: "8px" }}>
                <img src={FailedIcon} alt="info-icon" className="mr-8" />
              </span>
              <span>
                The selected program is ineligible. Please request a waiver to
                proceed with submission.
              </span>
            </div>
          )}
        {/* {showBuyDownOptions && <BuyDownOptionsWithPrevValues
            currentValues={currentValues}
            requestedValues={requestedValues}
          />} */}
      </div>
    </>
  );
};

export const LockInSelectionPopup = (props: any) => {
  const {
    showPopup,
    hidePopup,
    loansArray,
    onSubmit,
    loanId,
    loanStage,
    updateRateLock,
    overriddenValues,
    runFesEval,
    lockinValues = {},
    loanDetails,
    isPostSubmit,
    rateSheetType,
    eligibleTakeOutPartnerResult,
    setCurrentSelectedPartner,
    currentSelectedPartner,
    loansWithoutAssignedPartner,
    takeOutPartnerList,
    rateLockOptions,
    takeoutPartnerId,
    loansResult,
    noLocks,
    criticalTests,
    partnerWiseRateLockOptions,
    enableSubmitWithNoLocks
  } = props;

  const { dailyCreditLimit } = eligibleTakeOutPartnerResult ?? {};
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );

  const isWithinDailyLimit =
    dailyCreditLimit &&
    !dailyCreditLimit[currentSelectedPartner?.partnerId]?.isWithinLimit;

  const [lockInSelections, setLockInSelections] = useState<{
    [key: string]: string;
  }>({});
  const dispatch = useDispatch();
  const excelData = getExcelDataInSession();
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [showWaiverPage, setShowWaiverPage] = useState<boolean>(false);
  const [loanDataForWaiver, setLoanDataForWaiver] =
    useState<loanDataInterface | null>(null);
  const [selectedProgramDetails, setSelectedProgramDetails] = useState<any>({});
  const [dropDownLoanOptions, setDropDownLoanOptions] = useState<any[]>([]);
  const { loanIdForWaiver } = loanDataForWaiver || {};
  const [selectedLoan, setSelectedLoan] = useState<any>({});
  const [globalFilteredPartners, setGlobalFilteredPartners] = useState<any[]>(
    []
  );
  const [view, setView] = useState("pricing");
  const [loanIsNotLockedInfo, setLoanIsNotLockedInfo] = useState(false);

  const showDisabledOptionsMessage = useMemo(() => {
    return lockinValues?.[loanId]?.lockValues?.some((it: any) => it?.disabled);
  }, [lockinValues, loanId]);

  const defaultLockInPeriod = useMemo(() => {
    return lockinValues?.[loanId]?.lockValues?.[0]?.value ?? "";
  }, [lockinValues, loanId, excelData]);

  const handleLockInSelection = useCallback(
    (config: { [key: string]: string }) => {
      setLockInSelections({ ...lockInSelections, ...config });
    },
    [lockInSelections]
  );

  const updatedLoans = useMemo(() => {
    return (
      loansArray?.map((it: any) => ({
        ...it,
        rateLockPeriod:
          lockInSelections?.[it?.toorakLoanId] ?? defaultLockInPeriod,
        assignedPartner: currentSelectedPartner?.assignedPartner,
        takeOutPartner: currentSelectedPartner?.partnerId
      })) ?? []
    );
  }, [
    loansArray,
    lockInSelections,
    defaultLockInPeriod,
    currentSelectedPartner
  ]);

  const updatedOptions: any[] = useMemo(() => {
    if (!selectedRowsData.length) return [];
    return (
      lockinValues?.[selectedRowsData[0].toorakLoanId]?.lockValues?.map(
        (it: ObjectType) => ({
          ...it,
          style: { width: "250px" }
        })
      ) ?? []
    );
  }, [lockinValues, selectedRowsData]);

  const lockinValuesMap: any = useMemo(() => {
    if (!loansArray?.length) return;
    const valuesMap: ObjectType = {};
    const uniqueAssignedPartners: { [key: string]: any } = {};
    const uniqueAssignedPartnersArray = loansArray.reduce(
      (acc: any[], obj: { assignedPartner: any }) => {
        const { assignedPartner } = obj;

        if (!uniqueAssignedPartners[assignedPartner]) {
          uniqueAssignedPartners[assignedPartner] = true;
          acc.push(obj);
        }
        return acc;
      },
      []
    );
    uniqueAssignedPartnersArray.forEach((loan: any) => {
      const partnerId =
        lockinValues?.[loan.toorakLoanId].partnerId ||
        lockinValues?.[loan.toorakLoanId].assignedPartner;
      valuesMap[partnerId] = lockinValues?.[loan.toorakLoanId]?.lockValues;
    });
    return valuesMap;
    // eslint-disable-next-line
  }, [lockinValues, selectedRowsData]);

  const requestLockApproval = useMemo(() => {
    return (
      loansResult?.[loanId]?.[loanStage]?.[currentSelectedPartner.partnerId]
        ?.loanResult?.[loanId]?.loanCharacterisations
        ?.rateLockApprovalRequired === "Yes"
    );
  }, [loansResult, loanId, loanStage, currentSelectedPartner]);

  const handleSuccess = async (submitWithoutLock?: boolean) => {
    try {
      dispatch(showLoader());
      const payload = updatedLoans.map((data: any) => {
        const returnPayload: any = {
          loanId: data.toorakLoanId,
          loanDetails: {}
        };
        if (!submitWithoutLock) {
          returnPayload.loanDetails.loanConfig = {
            rateLockPeriod:
              eligibleTakeOutPartnerResult?.eligiblePartners?.includes?.(
                currentSelectedPartner?.partnerId
              )
                ? data?.rateLockPeriod ?? null
                : null,
            rateLockApprovalRequired:
              eligibleTakeOutPartnerResult?.eligiblePartners?.includes?.(
                currentSelectedPartner?.partnerId
              )
                ? data?.rateLockPeriod
                  ? requestLockApproval
                    ? "PENDING"
                    : "APPROVED"
                  : null
                : null
          };
        }

        if (
          currentSelectedPartner?.partnerId !== takeoutPartnerId ||
          !eligibleTakeOutPartnerResult?.eligiblePartners?.includes(
            currentSelectedPartner?.partnerId
          )
        ) {
          returnPayload.loanDetails.loanInfo = {
            takeoutPartner: currentSelectedPartner?.partnerId,
            takeoutPartnerStatus: "OVERRIDDEN"
          };
        }
        return returnPayload;
      });
      onSubmit(payload);
      if (
        (!isDisable() &&
          !eligibleTakeOutPartnerResult?.eligiblePartners.includes(
            currentSelectedPartner?.partnerId
          )) ||
        submitWithoutLock
      ) {
        setLoanIsNotLockedInfo(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RunRuleForRateLock = async (loan: any, newLoanDetails: any) => {
    const newDs: any = {};
    const lopin =
      overriddenValues?.loanResult || overriddenValues?.propertiesResults
        ? MergeRecursive(overriddenValues, newDs)
        : JSON.parse(JSON.stringify(newDs));
    runFesEval(lopin, null, newLoanDetails);
  };

  const handleSuccessFES = async (overRidedata: any, filesList: any[]) => {
    try {
      dispatch(showLoader());
      // uncomment when fes rate locks are enabled
      // if (filesList.length > 0) {
      //   // go ahead
      //   // 1. upload the docs
      //   // 2. call to save the rate lock
      //   const secondaryIden = "TERMSHEET-FES";

      //   const body = getFileUpladBody(
      //     filesList[0]?.name,
      //     loanId,
      //     "LOAN",
      //     secondaryIden,
      //     secondaryIden
      //   );
      //   const preAssignedURL: any = await getPreAssignedURL(
      //     body,
      //     filesList[0]?.type
      //   );
      //   if (preAssignedURL.url && !preAssignedURL.Error) {
      //     const res: any = await uploadAWSFile(filesList[0], {}, preAssignedURL);
      //     if (res.status === 200) {
      //       const insertBody = [
      //         {
      //           name: filesList[0]?.name,
      //           path: preAssignedURL.url,
      //           box_document_path: "<box_document_path>",
      //           createdBy: getCookie("org_id"),
      //           updatedBy: getCookie("org_id"),
      //           tags: []
      //         }
      //       ];
      //       const primaryIdentifier = "LOAN";
      //       const insertDocsResp: any = await insertDocs(
      //         insertBody,
      //         loanId,
      //         secondaryIden,
      //         secondaryIden,
      //         primaryIdentifier
      //       );
      //       if (insertDocsResp.status !== 200) {
      //         return;
      //       }
      //     }
      //   }
      // }
      const payload = updatedLoans.map((data: any) => {
        return {
          loanId: data.toorakLoanId,
          rateLockType: "SIZER_RATE_LOCK",
          sizerRateLockPeriod: Number(data.rateLockPeriod)
          // rateLockPeriod: Number(data.rateLockPeriod)
        };
      });

      const response = await postRateLock(payload);
      const formattedLoanData: any = formatFesLoanDetailsForRedux(response[0]);
      dispatch(
        setLoanByIdSuccess({
          loan: formattedLoanData
        })
      );
      if (
        loanStage === LoanStage.fes &&
        currentSelectedPartner?.partnerId &&
        currentSelectedPartner?.partnerId !== takeoutPartnerId
      ) {
        const status = eligibleTakeOutPartnerResult?.eligiblePartners?.includes(
          takeoutPartnerId
        )
          ? "OVERRIDDEN"
          : "PENDING";
        formattedLoanData.loanDetails.loanInfo = {
          takeoutPartner: currentSelectedPartner?.partnerId,
          // takeoutPartnerStatus: "OVERRIDDEN"
          takeoutPartnerStatus: status
        };
      }
      // run rules here
      await RunRuleForRateLock(updatedLoans[0], {
        ...loanDetails,
        ...formattedLoanData.loanDetails
      });
      dispatch(hideLoader());
      hidePopup();
      if (updateRateLock) {
        updateRateLock();
        dispatch(
          showPopUpMessages({
            message: FESRateLockPoupBody(),
            header: FESRateLockMessageHeader()
          })
        );
      } else {
        dispatch(
          showPopUpMessages({
            message: MessageBody(lockinValues),
            header: MessageHeader()
          })
        );
      }
      // showing the TC msg, LPP-9097
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
      hidePopup();
    } finally {
      dispatch(hideLoader());
    }
  };

  const getHeader = () => {
    return showWaiverPage ? (
      <Button
        variant="contained"
        data-testid="takeout-header-modal"
        color="primary"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={() => {
          handleBack();
        }}
      >
        <span>{`Back to Program Selection`}</span>
      </Button>
    ) : loanStage === LoanStage.fes && takeOutPartnerList.length > 1 ? (
      "Submit Loan"
    ) : loanStage === LoanStage.fes || isPostSubmit ? (
      "Apply Rate Lock"
    ) : (
      "Submit Loan"
    );
  };

  useEffect(() => {
    if (!loanId || !loanStage) return;
    dispatch(getWaiverList(loanId, loanStage, true));
  }, [loanId, loanStage]);

  useEffect(() => {
    if (!loansWithoutAssignedPartner?.length) return;
    //check redux data, if there update setvariable & return else conti
    const options = loansWithoutAssignedPartner.map((loan: any) => {
      return {
        label: `${loan.loanId} / ${loan.excelData.primaryLoanId}`,
        value: loan.loanId,
        waiverDetails: waiversCreated,
        selectedProgramDetails,
        isReadyForSubmit: false,
        filteredPartners: []
      };
    });
    setLoanDataForWaiver({ loanIdForWaiver: options[0].value });
    setDropDownLoanOptions(options);
    // eslint-disable-next-line
  }, [loansWithoutAssignedPartner]);

  useEffect(() => {
    if (!loansWithoutAssignedPartner?.length) {
      setLoanDataForWaiver({
        loanIdForWaiver: loanId
      });
    }
  }, [loanId, loansWithoutAssignedPartner]);

  useEffect(() => {
    if (!loanDataForWaiver?.loanIdForWaiver) return;
    dispatch(
      getWaiverList(
        loansWithoutAssignedPartner?.length
          ? loanDataForWaiver.loanIdForWaiver
          : loanId,
        loanStage,
        true
      )
    );
    // eslint-disable-next-line
  }, [dispatch, loanDataForWaiver?.loanIdForWaiver]);

  const isSubmitDisabled = useMemo(() => {
      if (excelData) {
        /**
         * loop through the list and check for all the datain the list
         * update the isSReaduForSubmit:boolean based on the results
         * return true if for all the element resp is true
         */
        if (
          !(
            dropDownLoanOptions.length &&
            Object.keys(selectedLoan.selectedProgramDetails).length
          )
        ) {
          return true;
        }

        let dropDownLoanOptionsNew: any[] = JSON.parse(
          JSON.stringify(dropDownLoanOptions)
        );
        const isDisableResp = dropDownLoanOptions.filter(
          (it: any, idx: number) => {
            const isReadyForSubmit = checkSubmitDisabled(
              it.selectedProgramDetails,
              it.waiverDetails,
              it.filteredPartners
            );
            if (isReadyForSubmit) {
              dropDownLoanOptionsNew[idx].isReadyForSubmit = false;
            } else {
              dropDownLoanOptionsNew[idx].isReadyForSubmit = true;
            }
            return !isReadyForSubmit;
          }
        );
        setDropDownLoanOptions(dropDownLoanOptionsNew);
        // return isDisableResp.length === dropDownLoanOptions.length ? false : true;
        return isDisableResp.length !== dropDownLoanOptions.length;
        // }
      } else {
        return checkSubmitDisabled(
          selectedProgramDetails,
          waiversCreated,
          globalFilteredPartners
        );
      }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    selectedLoan?.value,
    // eslint-disable-next-line
    selectedLoan?.selectedProgramDetails,
    // eslint-disable-next-line
    selectedLoan?.waiverDetails,
    globalFilteredPartners
  ]);

  const isDisable = () => {
    if (noLocks) {
      return false;
    }
    if (isSubmitDisabled) {
      if (
        eligibleTakeOutPartnerResult?.eligiblePartners?.includes(
          currentSelectedPartner?.partnerId
        ) ||
        noLocks
      ) {
        return !partnerWiseRateLockOptions[currentSelectedPartner?.partnerId]
          ?.isRateLockEnabled;
      }
      return true;
    }
    return (
      isWithinDailyLimit ||
      (loansArray.length === 1 &&
      Object.keys(lockinValues?.[loanId]?.lockValues ?? {})?.length === 0
        ? false
        : loansArray.length !==
          updatedLoans.filter((it: any) => it.rateLockPeriod).length)
    );
  };

  useEffect(() => {
    if (currentSelectedPartner?.partnerId) {
      setSelectedProgramDetails({
        ...currentSelectedPartner,
        key: currentSelectedPartner?.partnerId
      });
    }
  }, [currentSelectedPartner]);

  function checkSubmitDisabled(
    selectedProgramDetails: { key: any },
    waiversCreated: any[],
    globalFilteredPartners: any[]
  ) {
    const selectedProgId = selectedProgramDetails?.key;
    if (
      !(
        // waiversCreated?.length &&
        (globalFilteredPartners?.length && selectedProgId)
      )
    )
      return true;
    const waiversCreatedFiltered = waiversCreated.filter(
      (it: any) => it?.takeoutPartnerId === selectedProgId
    );
    const partnersResultFiltered = globalFilteredPartners.find(
      (it: any) => it.key === selectedProgId
    );
    if (!partnersResultFiltered) return false;

    let flag: boolean = false;
    partnersResultFiltered?.fail?.forEach((item: any) => {
      if (
        item?.isCriticalTest &&
        item?.waiverDetails?.waiverStatus !== "Requested"
      ) {
        flag = true;
      }
    });
    return flag;

    // return !!partnersResultFilteredWithoutWaiver.length || !!openWaivers.length;
  }

  useEffect(() => {
    if (currentSelectedPartner?.partnerId) {
      setCurrentSelectedPartner(currentSelectedPartner);
    }
  }, []);

  const handleBack = () => {
    if (!loanIdForWaiver) return;
    dispatch(getWaiverList(loanIdForWaiver, loanStage, true));
    setShowWaiverPage(false);
    setCurrentSelectedPartner(currentSelectedPartner);
    setView(view);
  };

  const handleSubmit = () => {
    handleSuccess(true);
  };

  const popupBody = () => {
    const body = (
      <LockInselectionForMultiLoan
        lockIn={
          lockInSelections?.[selectedRowsData[0]?.toorakLoanId] ??
          defaultLockInPeriod
        }
        handleLockInSelection={handleLockInSelection}
        loansArray={updatedLoans}
        updatedOptions={updatedOptions}
        selectedRowsData={selectedRowsData}
        setSelectedRowsData={setSelectedRowsData}
        lockinValuesMap={lockinValuesMap}
        showDisabledOptionsMessage={showDisabledOptionsMessage}
        rateSheetType={rateSheetType}
        eligibleTakeOutPartnerResult={eligibleTakeOutPartnerResult}
        lockinValues={lockinValues}
        setCurrentSelectedPartner={setCurrentSelectedPartner}
        currentSelectedPartner={currentSelectedPartner}
        loanId={loanId}
        loansWithoutAssignedPartner={loansWithoutAssignedPartner}
        takeOutPartnerList={takeOutPartnerList}
        rateLockOptions={rateLockOptions}
        requestLockApproval={requestLockApproval}
        criticalTests={criticalTests}
        loansResult={loansResult}
        programSelectConfig={{
          isProgramSelectView: false,
          isLockPopUp: true,
          // onRequestWaiverClick: onRequestWaiverClick,
          currentSelectedPartner: currentSelectedPartner,
          selectedProgramDetails: selectedProgramDetails,
          takeOutPartnerLists: takeOutPartnerList,
          rateLockOptions: rateLockOptions,
          setCurrentSelectedPartner: setCurrentSelectedPartner,
          eligibleTakeOutPartnerResult: eligibleTakeOutPartnerResult,
          partnerWiseRateLockOptions: partnerWiseRateLockOptions
        }}
        setShowWaiverPage={setShowWaiverPage}
        showWaiverPage={showWaiverPage}
        handleBack={handleBack}
        setLoanDataForWaiver={setLoanDataForWaiver}
        setGlobalSelected={setSelectedProgramDetails}
        selectedProgramDetails={selectedProgramDetails}
        dropDownLoanOptions={dropDownLoanOptions}
        setDropDownLoanOptions={setDropDownLoanOptions}
        waiversCreated={waiversCreated}
        selectedLoan={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        globalFilteredPartners={globalFilteredPartners}
        setGlobalFilteredPartners={setGlobalFilteredPartners}
        showRateLockSelection={loanStage !== LoanStage.fes}
        isSubmitDisabled={isDisable()}
        loanStage={loanStage}
        setLoanIsNotLockedInfo={setLoanIsNotLockedInfo}
        loanIsNotLockedInfo={loanIsNotLockedInfo}
        // updateIsAllSelected
      />
    );
    return body;
  };

  const submitButtonText = useMemo(() => {
    if (isPostSubmit && !requestLockApproval) {
      return "Apply";
    }
    if (isPostSubmit && requestLockApproval) {
      return "Request Lock";
    }
    if (
      noLocks
      // && eligibleTakeOutPartnerResult?.eligiblePartners?.length > 0
    ) {
      return "Ok";
    }
    // if (noLocks && eligibleTakeOutPartnerResult?.eligiblePartners?.length === 0) {
    //   return "Submit";
    // }
    if (loanStage === LoanStage.fes && takeOutPartnerList.length > 1) {
      return "Submit";
    }
    if (requestLockApproval) {
      return "Request Lock";
    }
    if (
      eligibleTakeOutPartnerResult?.eligiblePartners.includes(
        currentSelectedPartner?.partnerId
      )
    ) {
      return "Lock &  Submit";
    }
    if (
      !eligibleTakeOutPartnerResult?.eligiblePartners.includes(
        currentSelectedPartner?.partnerId
      )
    ) {
      return "Submit";
    }
    return "Lock & Submit";
  }, [
    isPostSubmit,
    lockinValues,
    loanId,
    loanStage,
    takeOutPartnerList,
    loansResult,
    currentSelectedPartner,
    eligibleTakeOutPartnerResult
  ]);

  const successHandler = () => {
    if (loanStage !== LoanStage.fes && noLocks) {
      hidePopup();
      return;
    } else {
      loanStage === LoanStage.fes ? handleSuccessFES("", []) : handleSuccess();
    }
  };

  const hideSubmitWithoutLock = useMemo(() => {
    return (
      enableSubmitWithNoLocks &&
      (isPostSubmit ||
        requestLockApproval ||
        !eligibleTakeOutPartnerResult?.eligiblePartners.includes(
          currentSelectedPartner?.partnerId
        ) ||
        loanStage === LoanStage.fes)
    );
  }, [
    isPostSubmit,
    requestLockApproval,
    eligibleTakeOutPartnerResult,
    currentSelectedPartner,
    loanStage
  ]);

  return (
    <>
      <ConfirmFormModal
        isOpen={showPopup}
        handleClose={() => {
          hidePopup();
          setCurrentSelectedPartner({
            assignedPartner: "",
            partnerId: ""
          });
        }}
        primaryBtnName={submitButtonText}
        header={getHeader()}
        body={popupBody()}
        confirmCondition={isDisable()}
        successHandler={successHandler}
        containerStyle={{ minWidth: noLocks ? "400px" : "1000px" }}
        size={800}
        footerStyles={
          showWaiverPage
            ? { display: "none" }
            : { paddingTop: "0px", marginTop: "25px" }
        }
        hidePrimaryBtn={showWaiverPage}
        hideSecondaryBtn={
          lockinValues?.[loanId]?.lockValues?.length === 0 || showWaiverPage
        }
        hideSecondPrimaryBtn={hideSubmitWithoutLock}
        secondPrimaryBtnAction={handleSubmit}
        secondPrimaryBtnName="Submit"
      />
    </>
  );
};
