import { createSlice } from "@reduxjs/toolkit";
export interface TasksStore {
  tasks: any;
  unfinishedTasks: any;
  taskUsersAndTaskList: any;
  // budgetApprovalHistory: any;
}

const initialState: TasksStore = {
  tasks: [],
  unfinishedTasks: [],
  taskUsersAndTaskList: []
  // budgetApprovalHistory: []
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    updateTasks: (state, action) => {
      const currentTasks = JSON.parse(
        JSON.stringify(state.tasks?.[0]?.assignedTasks)
      );
      action.payload.forEach((item: { taskId: any }) => {
        const index = currentTasks.findIndex(
          (obj: { taskId: any }) => obj.taskId === item.taskId
        );
        if (index > -1) {
          currentTasks[index] = item;
        }
      });
      state.tasks[0].assignedTasks = currentTasks;
    },
    setUnfinishedTasksCount: (state, action) => {
      state.unfinishedTasks = action.payload;
    },
    setUsersAndTaskList: (state, action) => {
      state.taskUsersAndTaskList = action.payload;
    }
    // updateBudgetApprovalHistory: (state, action) => {
    //   state.budgetApprovalHistory = action.payload;
    // }
  }
});

// Export the action creators
export const {
  setTasks,
  updateTasks,
  setUnfinishedTasksCount,
  setUsersAndTaskList
  // updateBudgetApprovalHistory
} = tasksSlice.actions;

// Export the reducer
export const TasksReducer = tasksSlice.reducer;

// export const TasksReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case SET_TASKS: {
//       const nextState = produce(state, draftState => {
//         draftState.tasks = action.payload;
//       });
//       return nextState;
//     }
//     case UPDATE_TASKS: {
//       const nextState = produce(state, draftState => {
//         const currentTasks = JSON.parse(
//           JSON.stringify(draftState.tasks?.[0]?.assignedTasks)
//         );
//         action.payload.forEach((item: any) => {
//           const index = currentTasks.findIndex(
//             (obj: any) => obj.taskId === item.taskId
//           );
//           if (index > -1) {
//             currentTasks[index] = item;
//           }
//         });
//         draftState.tasks[0].assignedTasks = currentTasks;
//       });
//       return nextState;
//     }
//     case SET_UNFINISHED_TTF_TASKS_COUNT: {
//       const nextState = produce(state, draftState => {
//         draftState.unfinishedTasks = action.payload;
//       });
//       return nextState;
//     }
//     case SET_USERS_AND_TASK_LIST: {
//       const nextState = produce(state, draftState => {
//         draftState.taskUsersAndTaskList = action.payload;
//       });
//       return nextState;
//     }
//     default:
//       return state;
//   }
// };
