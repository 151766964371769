/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Tooltip, Theme, Checkbox } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  LoanStage,
  GridDatePickerForm as DatePickerForm
} from "@toorak/tc-common-fe-sdk";
import { useDispatch } from "react-redux";
import doneCheckIcon from "../../../images/done-check.svg";
// import { GenerateSettlementDialogBody } from "./GenerateSettlementDialogBody";
import { confirmModel } from "./commonStyles";
import {
  checkIfValidDate,
  defaultDateFormat,
  yyyymmdd
} from "../../../utils/utils";
import { CustomizedTextFieldSettlements } from "../../../utils/CustomizedTextFieldSettlements";
import {
  getBundleFields,
  getMLPAdate
} from "../../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { staticBundleStatusList } from "../constants";
import { getLoanType } from "../../../config/config";

const styles: any = {
  purchaseMessage: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    whiteSpace: "pre-wrap"
  },
  checked: {
    color: "#39af90",
    fontSize: "inherit"
  },
  wrong: {
    color: "#b22a00",
    fontSize: "inherit"
  },
  infoMsg: {
    fontSize: "12px",
    color: "#32325d",
    fontWeight: "normal"
  },
  infoIcon: {
    width: "12px",
    height: "12px"
  },
  checkListitemWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.1rem",
    whiteSpace: "nowrap"
  }
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "black",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    cursor: "pointer"
  }
}))(Tooltip);

const CustomToolTip = (props: any) => {
  const { listItems } = props;
  return (
    <HtmlTooltip
      placement="right-end"
      title={
        <>
          <ul style={{ margin: "0px", padding: "0px" }}>
            {listItems.map((item: any) => {
              return <li>{item}</li>;
            })}
          </ul>
        </>
      }
    >
      <InfoOutlinedIcon />
    </HtmlTooltip>
  );
};

interface PurchaseInitaitionErrorInterface {
  originators?: any;
  loanType?: any;
  loanStatus?: any;
  loanStage?: any;
  onHold?: any;
  takeoutPartnerStatus?: any;
  takeoutPartners?: any;
  hasTfAndNormal: boolean;
  isRateLockAppliedForAllValue: boolean;
  tableFundingLoans?: any;
  advanceFundingLoans?: any;
  warehouseFundedLoans?: any;
  phase1ClosingDates?: any;
  showCollateralMessage?: any;
  debtYield?: any;
  debtLoans?: any;
}

export const purchaseInitaitionError = {
  header: (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={1}>
        <WarningIcon style={{ color: "#f75676" }} />
      </Grid>
      <Grid item xs={11}>
        The loans selected by you can not be bundled together to initiate
        purchase. Please read the below criterion.
      </Grid>
    </Grid>
  ),
  body: (args: PurchaseInitaitionErrorInterface) => {
    const {
      hasTfAndNormal,
      loanStage,
      tableFundingLoans,
      advanceFundingLoans,
      warehouseFundedLoans,
      loanStatus,
      takeoutPartnerStatus,
      takeoutPartners,
      onHold,
      originators,
      isRateLockAppliedForAllValue,
      loanType,
      phase1ClosingDates,
      showCollateralMessage,
      debtYield,
      debtLoans
    }: any = args;
    let wrongLoanStage: boolean = false;
    if (loanStage.size !== 1) {
      wrongLoanStage = true;
    }
    if (
      [...loanStage][0] !== LoanStage.post &&
      tableFundingLoans?.length === 0 &&
      advanceFundingLoans?.length === 0
    ) {
      wrongLoanStage = true;
    }
    let wrongloanStatus: boolean = false;
    if (loanStatus.size !== 1) {
      wrongloanStatus = true;
    }
    if ([...loanStatus][0] !== LoanStatusEnum.Approved) {
      wrongloanStatus = true;
    }
    let isOnHold: boolean = false;
    if ([...onHold].filter((ele) => ele && ele !== "NONE").length) {
      isOnHold = true;
    }
    return (
      <ul>
        <li>
          <p style={styles.checkListitemWrapper}>
            You cannot select loans from multiple <b>&apos;Originators&apos;</b>{" "}
            initiate purchase{" "}
            {!originators.size || originators === "disabled" ? (
              <BlockIcon style={styles.wrong} />
            ) : originators.size !== 1 ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
            {!originators.size || originators === "disabled" ? null : (
              <CustomToolTip listItems={[...originators]} />
            )}
          </p>
        </li>
        <li>
          <p style={styles.checkListitemWrapper}>
            <b>&apos;Stage&apos;</b>&nbsp;of the non table fund loans selected
            sholud be&nbsp;<b>&apos;Post-Close&apos;</b>&nbsp;
            {!loanStage.size || loanStage === "disabled" ? (
              <BlockIcon style={styles.wrong} />
            ) : wrongLoanStage ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
            {!loanStage || loanStage === "disabled" ? null : (
              <CustomToolTip listItems={[...loanStage]} />
            )}
          </p>
        </li>
        <li>
          <p style={styles.checkListitemWrapper}>
            <b>&apos;Status&apos;</b>&nbsp;of the loans selected should be{" "}
            <b>&apos;Approved&apos;</b>&nbsp;
            {!loanStatus.size || loanStatus === "disabled" ? (
              <BlockIcon style={styles.wrong} />
            ) : wrongloanStatus ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
            {!loanStatus.size || loanStatus === "disabled" ? null : (
              <CustomToolTip listItems={[...loanStatus]} />
            )}
          </p>
        </li>
        <li>
          <p style={styles.checkListitemWrapper}>
            <b>&apos;Loan Type&apos;</b>&nbsp; of the loans selected should be{" "}
            &nbsp;<b>&apos;Same&apos;</b>&nbsp;
            {!loanType.size || loanType === "disabled" ? (
              <BlockIcon style={styles.wrong} />
            ) : loanType.size !== 1 ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
            {!loanType.size || loanType === "disabled" ? null : (
              <CustomToolTip listItems={[...loanType]} />
            )}
          </p>
        </li>
        <li>
          <p style={styles.checkListitemWrapper}>
            Purchase cannot be initiated with loans &nbsp;
            <b>&apos;On Hold&apos;</b>&nbsp;
            {!onHold.size || onHold === "disabled" ? (
              <BlockIcon style={styles.wrong} />
            ) : isOnHold ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
            {!onHold.size || onHold === "disabled" ? null : (
              <CustomToolTip
                listItems={[...onHold].map((val) =>
                  val && val !== "NONE"
                    ? `Put on Hold by ${val}`
                    : "Not on hold"
                )}
              />
            )}
          </p>
        </li>
        <li>
          <p style={styles.checkListitemWrapper}>
            Purchase cannot be initiated for &nbsp;<b>&apos;Table Fund&apos;</b>
            &cedil; &nbsp;<b>&apos;Advance Fund&apos;</b>
            &cedil; &nbsp;<b>&apos;Warehouse Fund&apos;</b>
            &nbsp; and &nbsp;<b>&apos;Normal&apos;</b>&nbsp; loans
            together&nbsp;
            {hasTfAndNormal ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
          </p>
        </li>
        {[...loanType][0] === getLoanType[1].loanCreationName && (
          <li>
            <p style={styles.checkListitemWrapper}>
              Purchase cannot be initiated for &nbsp;
              <b>&apos;Loans without Rate-Lock.&apos;</b>
              &nbsp;
              {isRateLockAppliedForAllValue ? (
                <CheckIcon style={styles.checked} />
              ) : (
                <ClearIcon style={styles.wrong} />
              )}
            </p>
          </li>
        )}
        {warehouseFundedLoans.length > 0 && (
          <li>
            <p style={styles.checkListitemWrapper}>
              <b>&apos;Phase 1 Closing Date&apos;</b> of the wharehouse fund
              loans selected should be&nbsp;<b>&apos;Same Vaild Date&apos;</b>
              &nbsp;and of&nbsp;<b>&apos;Bridge&apos;</b>&nbsp;loan type.
              {phase1ClosingDates?.size !== 1 || ![...phase1ClosingDates][0] ? (
                <ClearIcon style={styles.wrong} />
              ) : (
                <CheckIcon style={styles.checked} />
              )}
            </p>
          </li>
        )}
        {[...loanType][0] === getLoanType[1].loanCreationName && (
          <li>
            <p style={styles.checkListitemWrapper}>
              <b>&apos;DSCR&apos; </b> loan should{" "}
              <b>&apos;must have Assigned Partner&apos; </b>.
              <b>&apos;TakeoutPartnerStatus&apos; </b> should not be &apos;
              <b>PENDING&apos;</b>. Also, all selected loans should have &apos;{" "}
              <b>same Assigned Partner&apos;</b>.
              {takeoutPartners.length === 0 ||
              new Set(takeoutPartners).size > 1 ||
              takeoutPartnerStatus.includes("PENDING") ? (
                <ClearIcon style={styles.wrong} />
              ) : (
                <CheckIcon style={styles.checked} />
              )}
            </p>
          </li>
        )}
        <li>
          <p style={styles.checkListitemWrapper}>
            Purchase cannot be initiated for Loans with &nbsp;
            <b>Negative Debt Yield</b>
            {debtLoans?.length > 1 ? <><b>:&nbsp;Loan Id:&nbsp;</b>{debtLoans.join(", ")}</> : null}&nbsp;
            {[...debtYield]?.some((dYield: any) => dYield < 0) ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
          </p>
        </li>
        {/* <li>
          <p style={styles.checkListitemWrapper}>
            <span><b>Collateral</b> should be marked as <b>Yes</b> for purchase Initiating a loan&nbsp;</span>
            {showCollateralMessage ? (
              <ClearIcon style={styles.wrong} />
            ) : (
              <CheckIcon style={styles.checked} />
            )}
          </p>
        </li> */}
      </ul>
    );
  }
};

export const purchaseInitaitionSuccess = {
  header: (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginRight: "10px",
            borderBottom: "solid 1px #f2f2f2"
          }}
        >
          <img
            alt="done"
            src={doneCheckIcon}
            style={{ height: "16px", width: "16px" }}
          />
        </div>
      </Grid>
      <Grid item>
        <span>Purchase initiated successfully</span>
      </Grid>
    </Grid>
  ),
  body: (
    <>
      <p style={styles.purchaseMessage}>
        These purchase initiated loans are bundled against the respective
        originator, and you can find the same in ‘Purchase & Settlement’ tab.
      </p>
      <p />
      <p />
      <p style={styles.purchaseMessage}>
        System has notified the originator about purchase initiation. Once they
        confirm the loan details and custody documents, the team can generate
        settlement statement.
      </p>
    </>
  )
};

export interface GenerateSettlementPopUpProps {
  noOfLoans: number;
  bundleId: string;
  closingDate: any;
  setClosingDate: any;
}

// export const GenerateSettlementPopUp = (
//   props: GenerateSettlementPopUpProps
// ) => {
//   const { noOfLoans, bundleId, closingDate, setClosingDate } = props;
//   return {
//     head: `Generate Settlement (${noOfLoans} Loans | BID: ${bundleId})`,
//     body: (
//       <GenerateSettlementDialogBody
//         closingDate={closingDate}
//         setClosingDate={setClosingDate}
//       />
//     )
//   };
// };

interface OptionalCommentProps {
  label?: string;
  comment: string;
  setComment: any;
  helperText?: string;
  customStyles?: any;
  noLabel?: boolean;
  disableEdit?: boolean;
  required?: boolean;
}

export const OptionalComment = (props: OptionalCommentProps) => {
  const {
    comment,
    setComment,
    label,
    customStyles,
    noLabel,
    disableEdit,
    required
  } = props;
  const classes = confirmModel();
  return (
    <Grid
      container
      direction="column"
      className={classes.body}
      style={customStyles?.body}
    >
      {!noLabel ? (
        <Grid item>
          <div className={classes.label} style={customStyles?.lable}>
            {label || "Comment"}
            {required ? <span className={classes.requiredLabel}>*</span> : null}
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <div
          className={classes.textAreaWrapper}
          style={customStyles?.textAreaWrapper}
        >
          <textarea
            className={classes.textArea}
            style={customStyles?.textArea}
            value={comment}
            onChange={(e: any) => setComment(e.target.value)}
            placeholder="Write Here"
            disabled={disableEdit}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export const FinalizeSettlementBody = (
  props: OptionalCommentProps & {
    orgId: string;
    mlpaDate: any;
    setMlpaDate: any;
    setWarnMsg: any;
    setOpenWarn: any;
    setMlpaEdit: any;
    setOpenFinalise: any;
    setPAReviewed: any;
    paReviewed: boolean;
    bundleId: string;
  }
) => {
  const dispatch = useDispatch();
  const {
    orgId,
    comment,
    setComment,
    label,
    mlpaDate,
    setMlpaDate,
    setWarnMsg,
    setOpenWarn,
    setMlpaEdit,
    setOpenFinalise,
    paReviewed,
    setPAReviewed,
    bundleId
  } = props;

  const [noMLPA, setnoMLPA] = useState<boolean>(true);
  useEffect(() => {
    setPAReviewed(false);
    const reqBody: any = {
      userId: orgId
    };
    dispatch(showLoader());
    const p1: any = getMLPAdate(reqBody);
    const p2: any = getBundleFields(bundleId);
    Promise.all([p1, p2])
      .then(([res, res2]: any) => {
        if (checkIfValidDate(new Date(res.data))) {
          setMlpaDate(res.data);
          setnoMLPA(false);
        } else {
          setMlpaDate("error");
          setnoMLPA(true);
          setOpenFinalise(false);
          setWarnMsg({
            header: "FAIL",
            body: "Error getting MLPA date."
          });
          setOpenWarn(true);
        }
        if (res2.data) {
          const bLM: any[] = res2.data?.bundleLandmarks || [];
          if (bLM.length) {
            const cmnts: any[] = bLM.filter(
              (itm: any) => itm.name === staticBundleStatusList[5]
            );
            setComment(
              cmnts.length ? cmnts[cmnts.length - 1]?.comments || "" : ""
            );
          }
        }
        dispatch(hideLoader());
      })
      .catch((err: any) => {
        console.log(err);
        setMlpaDate("error");
        setnoMLPA(true);
        setOpenFinalise(false);
        dispatch(hideLoader());
        setWarnMsg({
          header: "FAIL",
          body: "Error getting MLPA date."
        });
        setOpenWarn(true);
      });
  }, []);
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{
        width: "500px",
        padding: "0px 20px"
      }}
    >
      {!noMLPA ? (
        <>
          <Grid item xs={12}>
            <DatePickerForm
              avoidConverison
              formatterFunction={defaultDateFormat}
              dateFormatString="MM/DD/YYYY"
              value={mlpaDate}
              // isViewMode={false}
              isViewMode
              onChange={(date: any) => {
                if (
                  new Date(date).setHours(0, 0, 0, 0) <
                  new Date().setHours(0, 0, 0, 0)
                ) {
                  setMlpaEdit(true);
                  setMlpaDate(yyyymmdd(date));
                } else {
                  setWarnMsg({
                    header: "ALERT",
                    body: "MLPA date has to be a past date."
                  });
                  setOpenWarn(true);
                }
              }}
              label="MLPA Date"
              error={false}
              helperText=""
              editedLabel=""
              style={{ width: "100%" }}
              required
              renderInput={(propss: any) => (
                <CustomizedTextFieldSettlements
                  propss={propss}
                  value={defaultDateFormat(mlpaDate)}
                  error={false}
                  helperText=""
                  // isViewMode={false}
                  isViewMode
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <OptionalComment
              label={label}
              comment={comment}
              setComment={setComment}
              customStyles={{
                body: {
                  padding: 0,
                  width: "auto"
                },
                lable: {
                  marginBottom: "4px"
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Checkbox
                checked={paReviewed}
                onChange={() => setPAReviewed((preVal: boolean) => !preVal)}
                color="primary"
              />
              <span style={{ color: "#32325d", fontSize: "12px" }}>
                Purchase Advice document has been reviewed.
              </span>
            </Grid>
          </Grid>
        </>
      ) : noMLPA && mlpaDate !== "error" ? (
        <p>Checking if MLPA date is configured...</p>
      ) : null}
    </Grid>
  );
};

export const removeLoansSuccess = {
  header: (noOfLoans: number) => (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginRight: "10px",
            borderBottom: "solid 1px #f2f2f2"
          }}
        >
          <img
            alt="done"
            src={doneCheckIcon}
            style={{ height: "16px", width: "16px" }}
          />
        </div>
      </Grid>
      <Grid item>
        <span>
          {`Removed ${noOfLoans} ${
            noOfLoans > 1 ? "Loans" : "Loan"
          } from bundle successfully`}
        </span>
      </Grid>
    </Grid>
  ),
  body1: (
    <p style={styles.infoMsg}>
      System will notify the originator about this action.
    </p>
  ),
  body2: (
    <p style={styles.infoMsg}>
      System will automatically update <b>Settlement Statement</b> and will
      notify the originator about this action.
    </p>
  ),
  body3: (
    <>
      <p style={styles.infoMsg}>
        System will automatically update{" "}
        <b>Settlement Statement, MLS Tape, Data Tape & Purchase Advice</b>
        &nbsp;and the updated items will be available to download from the “
        <SettingsOutlinedIcon style={styles.infoIcon} /> More Action” button.
      </p>
      <p style={styles.infoMsg}>
        System will notify the originator about this action.
      </p>
    </>
  )
};

export const ExportSettlementBundle = (props: any) => {
  const { isLAT } = props;
  return (
    <div>
      <p style={{ margin: 0 }}>Export Settlement Bundle</p>
      <p style={{ color: "#8897aa", margin: 0 }}>
        {isLAT ? "(SS, MLS Tape, Data Tape, PA)" : "(SS, Data Tape, PA)"}
      </p>
    </div>
  );
};
