import { tabIds, tabStatus } from "../../create-loan/ttf-review/constants";
import {
  getTaskCount,
  getTaskDetails,
  updateTaskDetails
} from "../../network/apiService";
import { updateBudgetApprovalHistory } from "../budgetReview/budgetReview.reducer";
import {
  setTasks,
  setUnfinishedTasksCount,
  setUsersAndTaskList,
  updateTasks
  // updateBudgetApprovalHistory
} from "./Tasks.reducer";

// export const SET_TASKS = "SET_TASKS";
// export const UPDATE_TASKS = "UPDATE_TASKS";
// export const SET_UNFINISHED_TTF_TASKS_COUNT = "SET_UNFINISHED_TTF_TASKS_COUNT";
// export const SET_USERS_AND_TASK_LIST = "SET_USERS_AND_TASK_LIST";

export enum taskNames {
  TAG = "tag",
  SPLIT = "split",
  REVIEW = "review",
  RECONCILE = "reconcile",
  BUDGET_REVIEW = "budgetReview",
  BUDGET_APPROVE = "budgetApprove",
  INSPECTION_REVIEW = "inspectionReview",
  BUDGET_RECONCILE = "budgetReconcile"
}

export const routeMap: any = {
  [taskNames.TAG]: tabIds.DOCUMENT_TAGGING,
  [taskNames.SPLIT]: tabIds.DOCUMENTS_SPLIT,
  [taskNames.REVIEW]: tabIds.DOCUMENT_REVIEW
};

export const getTasks = async (
  dispatch: any,
  payload: any,
  loanSummary?: any,
  updateApproveTask = false,
  skipInitializingTasks = false
) => {
  return getTaskDetails(payload).then(async (response: any) => {
    // temporary change to add task if no task is available, as cloud function is not working. to be removed later
    let assignedTasks = response?.data?.[0]?.assignedTasks ?? [];
    if (!assignedTasks?.length && !skipInitializingTasks) {
      const taskNamesArr = [
        taskNames.SPLIT,
        taskNames.REVIEW,
        taskNames.TAG,
        taskNames.RECONCILE,
        taskNames.BUDGET_REVIEW,
        taskNames.BUDGET_APPROVE
      ];
      let prepareObj: any = [
        {
          resourceId: payload.resourceIds?.[0],
          resourceType: "LOAN",
          assignedTasks: []
        }
      ];
      // prepare the payload for missing tasks
      taskNamesArr.forEach((item) => {
        prepareObj[0].assignedTasks.push({
          op: "add",
          assigneePartyId: "UnAssigned",
          taskName: item,
          assignedBy: "None"
        });
      });
      const addResponse: any = await updateTaskDetails(prepareObj);
      assignedTasks = addResponse?.data?.[0]?.assignedTasks ?? [];
    }
    if (loanSummary) {
      assignedTasks.forEach((task: any) => {
        if (task?.taskName === taskNames.TAG) {
          task.taskStatus = loanSummary.documentsTaggingStatus;
        } else if (task?.taskName === taskNames.SPLIT) {
          task.taskStatus = loanSummary.documentsSplitStatus;
        } else if (task?.taskName === taskNames.REVIEW) {
          task.taskStatus = loanSummary.ttfReviewStatus;
        } else if (task?.taskName === taskNames.RECONCILE) {
          task.taskStatus = loanSummary.dataReconcilationStatus;
        }
      });
    }
    dispatch(setTasks(response?.data));
    if (updateApproveTask) {
      const budgetApproveTask = assignedTasks.filter(
        (task: { taskName: string }) => task.taskName === "budgetApprove"
      );
      dispatch(updateBudgetApprovalHistory(budgetApproveTask));
    }
  });
};

export const updateTaskData = (payload: any, dispatch: any) => {
  const newPayload = JSON.parse(JSON.stringify(payload));
  newPayload?.[0].assignedTasks.forEach((item: any) => delete item?.updatedOn);
  updateTaskDetails(newPayload).then((response: any) => {
    dispatch(updateTasks(response?.data?.[0]?.assignedTasks ?? []));
  });
};

function getCountMap(array: any) {
  return array.reduce((obj: any, item: any) => {
    if (obj[item.assigneePartyId]) {
      if (obj[item.assigneePartyId][item.taskName]) {
        obj[item.assigneePartyId][item.taskName] = {
          ...obj[item.assigneePartyId][item.taskName],
          [item.taskStatus]: item
        };
        return obj;
      }
      obj[item.assigneePartyId] = {
        ...obj[item.assigneePartyId],
        [item.taskName]: {
          [item.taskStatus]: item
        }
      };
      return obj;
    }
    return {
      ...obj,
      [item.assigneePartyId]: {
        [item.taskName]: {
          [item.taskStatus]: item
        }
      }
    };
  }, {});
}

export const getTasksCountForDifferentTasks = (payload: any, dispatch: any) => {
  const getTaskPromise: Promise<any>[] = [];
  payload.forEach((obj: any) => {
    getTaskPromise.push(getTaskCount(obj));
  });
  Promise.all(getTaskPromise).then((responseArr: any) => {
    let finalDataSet: any[] = [];
    responseArr.forEach((response: any) => {
      finalDataSet = [...finalDataSet, ...response?.data];
    });
    let countMap = getCountMap(finalDataSet);
    Object.keys(countMap).forEach((assigneePartyId: string) => {
      if (!countMap[assigneePartyId]?.[taskNames.TAG]) {
        [tabStatus.PENDING, tabStatus.INPROGRESS].forEach(
          (taskStatus: tabStatus) => {
            finalDataSet.push({
              assigneePartyId,
              taskName: taskNames.TAG,
              taskStatus,
              count: 0
            });
          }
        );
      } else {
        [tabStatus.PENDING, tabStatus.INPROGRESS].forEach(
          (taskStatus: tabStatus) => {
            if (!countMap[assigneePartyId]?.[taskNames.TAG]?.[taskStatus]) {
              finalDataSet.push({
                assigneePartyId,
                taskName: taskNames.TAG,
                taskStatus,
                count: 0
              });
            }
          }
        );
      }
    });
    dispatch(setUnfinishedTasksCount(finalDataSet));
  });
};

export const setUsersAndTasksList = (payload: any, dispatch: any) => {
  dispatch(setUsersAndTaskList(payload));
};
