/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import {
  ErrorPriority,
  errorTextForCode,
  getErrorMessageAndHeader
} from "./ErrorConfigData";
import { RootState } from "../stores/rootReducer";
import { clearErrors } from "../stores/loaderAndException/loaderAndException.action";
import { FlashMessages, FlashMessageType } from "./Components/FlashMessages";

/**
 * This component is used to handle various error codes including 400,500,503 etc (configured at interceptor).
 * Consumes ErrorContext which provides array of errors and a function to remove it from parent component.
 * In axios config, errorConfig.priority can be set as one of @enum ErrorPriority.
 * @returns error UI with message to user.
 */
export const ErrorComponentWrapper = () => {
  const dispatch = useDispatch();
  const [seggregatedErrors, setSeggregatedErrors] = React.useState<{
    criticalErrors: any[];
    mediumErrors: any[];
    lowLevelErrors: any[];
  }>({ criticalErrors: [], mediumErrors: [], lowLevelErrors: [] });
  const errors = useSelector<RootState, any[]>(
    (state) => state.LoaderAndExceptionsStore.apiErrors
  );
  useEffect(() => {
    const criticalErrors: any[] = [];
    const mediumErrors: any[] = [];
    const lowLevelErrors: any[] = [];
    if (errors?.length) {
      errors.forEach((item: any) => {
        const { errorPriority } = item.errorData?.config?.errorConfig || {};
        if (errorPriority === ErrorPriority.critical) {
          criticalErrors.push(item);
        } else if (errorPriority === ErrorPriority.low) {
          lowLevelErrors.push(item);
        } else {
          mediumErrors.push(item);
        }
      });
    }
    setSeggregatedErrors({ criticalErrors, lowLevelErrors, mediumErrors });
  }, [errors]);

  /**
   * if there is only one error, show message based on status code.
   * if there is more than one error, then show multiple messages only if there is specific error message provided at api level. Or else show a single generic error message.
   * @param errorsArray contains list of all error configs added in Interceptor
   * @returns html of error message shown in error pop up.
   */
  const getErrorMessage = (errorsArray: any[]) => {
    let errorMessagesArray: string[] = [];
    const requestInfoArray: any[] = [];
    if (errors.length === 1) {
      const { errorMessageBody, requestInfo } = getErrorMessageAndHeader(
        errorsArray[0]
      );
      console.log({ errorMessageBody, requestInfo, errorsArray });
      const response = errorsArray[0].errorData?.response?.data;
      if (response.errorValue === "COLLATERAL_LOAN_INVALID") {
        errorMessagesArray.push(response.message);
      } else {
        errorMessagesArray.push(errorMessageBody);
        requestInfoArray.push(requestInfo);
      }
    } else {
      errorsArray.forEach((errorItem: any, index: number) => {
        const { config } = errorItem.errorData;
        const { errorConfig } = config || {};
        if (errorConfig?.customErrorMessage?.body) {
          const { errorMessageBody, requestInfo } = getErrorMessageAndHeader(
            errorsArray[index]
          );
          errorMessagesArray.push(errorMessageBody);
          requestInfoArray.push(requestInfo);
        }
      });
    }
    if (errors.length && !errorMessagesArray.length) {
      errorMessagesArray.push(errorTextForCode("default"));
    } else {
      // removing duplicate errors here,in case same api was called multiple times.
      errorMessagesArray = errorMessagesArray.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
    }
    const messageUI = errorMessagesArray.map((msg) => {
      return (
        <Grid>
          <span>{msg}</span>
        </Grid>
      );
    });
    //To be removed or changed to proper ui later. Adding this rough ui to help automation team understand api failures better.
    const requestInfoUI = requestInfoArray.map((obj) => {
      return (
        <Grid style={{ overflow: "scroll", maxHeight: 80, marginTop: 20 }}>
          <span>{`${obj.method}: ${obj.url}`}</span>
          <br />
          <span>{`requestBody: ${obj.requestBody}`}</span>
          <br />
          <span>{`headers: ${JSON.stringify(obj.headers)}`}</span>
        </Grid>
      );
    });

    return [...messageUI, ...requestInfoUI];
  };
  if (
    errors.length === 1 &&
    errors[0].errorData?.config?.errorConfig?.errorPriority ===
      ErrorPriority.low
  ) {
    return (
      <FlashMessages
        type={FlashMessageType.error}
        errorMessage={getErrorMessage(errors)}
        id={errors[0].id}
      />
    );
  }
  if (errors.length) {
    return (
      <WarningModal
        isOpen
        handleClose={() => {
          if (seggregatedErrors.criticalErrors.length) {
            window.location.reload();
          }
          dispatch(clearErrors({ errorId: "na", closeAll: true }));
        }}
        primaryBtnName={
          seggregatedErrors.criticalErrors.length ? "Reload Page" : "OK"
        }
        header="Error"
        body={getErrorMessage(errors)}
        disableCloseOnOutsideClick
        closeDisable={seggregatedErrors.criticalErrors.length > 0}
      />
    );
  }
  return null;
};
