/* eslint-disable no-underscore-dangle */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  publicClient,
  LoanStage,
  LoanTypes,
  UserRole
} from "@toorak/tc-common-fe-sdk";
// import { getConfig, getLoanType } from "../../config/config";
// import uuidValidate from "uuid-validate";
import { LoanStatusEnum, BundleStatuses } from "@toorak/tc-common-util-sdk";
import { getConfig } from "../../config/config";
import {
  dispatchAndUpdate,
  downloadFile
  // formatLoanDetailsForRedux,
  // GET_LOAN_BY_ID_SUCCESS
} from "../../create-loan/create-loan.action";
import {
  staticBundleStatusList,
  filterKeyMapping,
  custodyDocuments,
  formatType,
  sanitizeValue,
  maskValIfOrg,
  PAconstants
} from "../../create-loan/purchase-and-settlements/constants";
import {
  isLATUser,
  isRole,
  isRoleBridge,
  isRoleDSCR
} from "../../utils/AccessManagement";
import { getCookie } from "../../utils/cookies";
import { yyyymmdd } from "../../utils/utils";
import {
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
import { getErrorConfigObject } from "../../ErrorHandler/ErrorConfigData";
import {
  LoanStructureTypes,
  ToorakProductEnum
} from "../../create-loan/constants/loanCreationDropDownValues";
// import { mapConfigMask } from "../../create-loan/purchase-and-settlements/MultiLoanEditTable/config";
import { TABLE_FUNDING, bucketName } from "../../utils/constants";
import {
  getRUChatCount,
  getUChatCount
} from "../commentsSection/comment.action";
import {
  BundleSellerMappingReq,
  DocuSignReqBody,
  MLPAPayload,
  onlyLoanFields,
  ssLoanFields,
  ssNonRuleField,
  ssRulesFields,
  ssRulesFieldsMask
} from "./helpers";
import {
  fetchSearchResultsLoanAgg,
  getAuditInfoLightWeight,
  getSigneeUsers
} from "../../network/apiService";
import { AuditResourceObj } from "../../network/helpers";
import { ObjectType } from "../../masterView/common";
import {
  emptySearchLoanID,
  // getFilterOrgList,
  setBundleChatCount,
  setBundleChatUnreadCount,
  setBundleList,
  setEditHistory,
  setFilterStatusList,
  // setLoanBundleData,
  setOrgFilter,
  setSearchedLoanID,
  // setTabViewRedux,
  setTotalBundleCount,
  setViewBundle,
  triggerBundleListingOnce,
  updatePollingData
  // updateRefreshBundleView
} from "./SettlementAndPurchase.reducer";
import { isHidden } from "../../create-loan/property-details/PropertyDetailsPropertyInformation";
// import moment from "moment";

export const PRIMARY_IDENTIFIER = "SETTLEMENT";
export const DRAW_FILE_IDENTIFIER = "DRAW_DETAILS_DOCUMENT";
export const BUCKET_NAME = bucketName;
// export const MAX_FILE_SIZE = 5242880; // 5Mb in bytes
export const MAX_FILE_SIZE = 20971520; // 20Mb in bytes
export const EXTENSION_LIST = [
  // doc format
  "docs",
  "docx",
  // pdf format
  "pdf",
  // image format
  "jpg",
  "jpeg",
  "JPEG",
  "png",
  "PNG",
  // excel format
  "xlsx"
];

export const EXPORT_SETTLEMENT_STATEMENT = "settlementStatement";
export const EXPORT_MLS_TAPE = "mlsTape";
export const EXPORT_DATA_TAPE = "dataTape";
export const EXPORT_SETTLEMENT_BUNDLE = "settlementBundle";

export const SET_REFRESH = "SET_REFRESH";
export const CLEAR_REFRESH = "CLEAR_REFRESH";
export const ACTIVE_BUNDLE = "ACTIVE_BUNDLE";
export const UPDATE_NEW_SIGNEE_ACTIVE_BUNDLE =
  "UPDATE_NEW_SIGNEE_ACTIVE_BUNDLE";
export const GET_FILTER_STATUS_LIST = "GET_FILTER_STATUS_LIST";
// export const GET_BUNDLE_COUNT = "GET_BUNDLE_COUNT";
export const SET_TOTAL_BUNDLE_COUNT = "SET_TOTAL_BUNDLE_COUNT";
export const SET_BUNDLE_LIST = "SET_BUNDLE_LIST";
export const SET_PURCHASE_LOANS = "SET_PURCHASE_LOANS";
export const SET_TAB_VIEW = "SET_TAB_VIEW";
export const SET_ORG_WARN_ALERT = "SET_ORG_WARN_ALERT";
export const SET_BUNDLE_SELECTED_LOANS = "SET_BUNDLE_SELECTED_LOANS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_SEARCHED_LOANID = "SET_SEARCHED_LOANID";
export const SET_SELECTED_BUNLDE_LOANS = "SET_SELECTED_BUNLDE_LOANS";
export const TRIGGER_BUNDLE_LISTING_ONCE = "TRIGGER_BUNDLE_LISTING_ONCE";
export const RESET_PAGE_NUMBER = "RESET_PAGE_NUMBER";
export const RESET_LOANS_SELECTIONS = "RESET_LOANS_SELECTIONS";
export const SET_LOAN_TABLE_TO_EXPORT = "SET_LOAN_TABLE_TO_EXPORT";
export const CALCULATED_BUNDLE_FIELDS = "CALCULATED_BUNDLE_FIELDS";
export const STORE_UPDATED_FIELDS_REGENERATE =
  "STORE_UPDATED_FIELDS_REGENERATE";
export const RECALL_CALC_FIELDS = "RECALL_CALC_FIELDS";
export const SET_LOAN_BUNDLE_DATA = "SET_LOAN_BUNDLE_DATA";
export const SET_BUNDLE_CHAT_COUNT = "SET_BUNDLE_CHAT_COUNT";
export const SET_BUNDLE_CHAT_UREAD_COUNT = "SET_BUNDLE_CHAT_UREAD_COUNT";
export const SET_BUNDLE_CUSTOMER_DATA = "SET_BUNDLE_CUSTOMER_DATA";
export const SET_PARTY_ID_OBJ = "SET_PARTY_ID_OBJ";
export const SET_VIEW_BUNDLE = "SET_VIEW_BUNDLE";
export const SET_CLEAR_P_AND_S_SORTING = "SET_CLEAR_P_AND_S_SORTING";
export const SET_P_AND_S_FILTER = "SET_P_AND_S_FILTER";
export const UPDATE_POLLING_DATA = "UPDATE_POLLING_DATA";
export const SET_THIRD_PARTY_SERVICED_VALUE = "SET_THIRD_PARTY_SERVICED_VALUE";
export const SET_THIRD_PARTY_SERVICERS = "SET_THIRD_PARTY_SERVICERS";
export const SELECT_ALL_THIRD_PARTY_SERVICERS =
  "SELECT_ALL_THIRD_PARTY_SERVICERS";
export const SELECT_ALL_SERVICERS = "SELECT_ALL_SERVICERS";
export const RESET_THIRD_PARTY_VALUES = "RESET_THIRD_PARTY_VALUES";
export const EMPTY_SEARCH_LOANID = "EMPTY_SEARCH_LOANID";
export const SET_EDIT_HISTORY = "SET_EDIT_HISTORY";
export const GET_FILTER_ORG_LIST = "GET_FILTER_ORG_LIST";
export const SET_MIN_BUNDLE_CREATED_ON_DATE = "SET_MIN_BUNDLE_CREATED_ON_DATE";
export const SET_BUNDLE_DATE_RANGE = "SET_BUNDLE_DATE_RANGE";
export const GET_FILTER_LOAN_TYPE_LIST = "GET_FILTER_LOAN_TYPE_LIST";
export const GET_TAKEOUTPARTNER_LIST = "GET_TAKEOUTPARTNER_LIST";
export const CLEAR_BUNDLE_DATA = "CLEAR_BUNDLE_DATA";
export const REFRESH_BUNDLE_VIEW = "REFRESH_BUNDLE_VIEW";
export const OPEN_EDIT_LOAN_MODAL = "OPEN_EDIT_LOAN_MODAL";
export const OPEN_VIEW_LOAN_MODAL = "OPEN_VIEW_LOAN_MODAL";
export const OPEN_DRAW_EDIT_LOAN_MODAL = "OPEN_DRAW_EDIT_LOAN_MODAL";
export const SET_SAVED_BUNDLE_FILTERS = "SET_SAVED_BUNDLE_FILTERS";
export const SAVE_EDITED_LOANS_DATA = "SAVE_EDITED_LOANS_DATA";
export const REMOVE_EDITED_LOANS_DATA = "REMOVE_EDITED_LOANS_DATA";
export const CLEAR_EDITED_LOANS_DATA = "CLEAR_EDITED_LOANS_DATA";
export const SAVE_EDITED_LOANS_DATA_COL_LEVEL =
  "SAVE_EDITED_LOANS_DATA_COL_LEVEL";
export const REMOVE_EDITED_LOANS_DATA_COL_LEVEL =
  "REMOVE_EDITED_LOANS_DATA_COL_LEVEL";
export const SAVE_SELECTED_LOANS_BT = "SAVE_SELECTED_LOANS_BT";
export const SET_AUDIT_STORE = "SET_AUDIT_STORE";

// export const setAuditDataBIview = (payload: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_AUDIT_STORE,
//       payload
//     });
//   };
// };

// export const setSelectedLoansBT = (payload: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SAVE_SELECTED_LOANS_BT,
//       payload
//     });
//   };
// };

// export const clearEditedLoansData = () => {
//   return (dispatch: any) => {
//     dispatch({
//       type: CLEAR_EDITED_LOANS_DATA
//     });
//   };
// };

// export const setSaveEditedLoansData = (
//   loan: string,
//   colKey: string,
//   value: any
// ) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SAVE_EDITED_LOANS_DATA,
//       payload: {
//         loan,
//         colKey,
//         value
//       }
//     });
//   };
// };

// export const rmColLvlSaveEditedLoansData = (id: string) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: REMOVE_EDITED_LOANS_DATA_COL_LEVEL,
//       payload: {
//         cellKey: mapConfigMask[id] || id
//       }
//     });
//   };
// };

// export const colLvlSaveEditedLoansData = (
//   loanIds: any[],
//   id: string,
//   value: string
// ) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SAVE_EDITED_LOANS_DATA_COL_LEVEL,
//       payload: {
//         cellKey: mapConfigMask[id] || id,
//         loanIds,
//         value
//       }
//     });
//   };
// };

// export const removeSaveEditedLoansData = (loan: any, id: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: REMOVE_EDITED_LOANS_DATA,
//       payload: {
//         rowKey: loan,
//         cellKey: mapConfigMask[id] || id
//       }
//     });
//   };
// };

// export const setSavedBundleFilters = (payload: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SAVED_BUNDLE_FILTERS,
//       payload
//     });
//   };
// };

// export const setOpenViewModal = (val: {
//   open: boolean;
//   loanId: string;
//   fieldList: any[];
// }) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: OPEN_VIEW_LOAN_MODAL,
//       payload: val
//     });
//   };
// };

// export const setOpenEditModal = (val: { open: boolean; loanId: string }) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: OPEN_EDIT_LOAN_MODAL,
//       payload: val
//     });
//   };
// };

// export const setOpenDrawEditModal = (val: {
//   open: boolean;
//   loanId: string;
// }) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: OPEN_DRAW_EDIT_LOAN_MODAL,
//       payload: val
//     });
//   };
// };

// export const setBundleRefresh = (val: boolean) => {
//   return (dispatch: any) => {
//     dispatch(updateRefreshBundleView(val));
//   };
// };

// export const updateThirdPartyServiceValue = (isThirdPartyServiced: boolean) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_THIRD_PARTY_SERVICED_VALUE,
//       isThirdPartyServiced
//     });
//   };
// };

// export const SelectDeselectAllThirdParty = (servicers: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SELECT_ALL_THIRD_PARTY_SERVICERS,
//       servicers
//     });
//   };
// };

// export const updateThirdPartyServicers = (
//   servicer: string,
//   isChecked: boolean
// ) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_THIRD_PARTY_SERVICERS,
//       servicer,
//       isChecked
//     });
//   };
// };

// export const resetThirdPartyServicerValue = () => {
//   return (dispatch: any) => {
//     dispatch({
//       type: RESET_THIRD_PARTY_VALUES
//     });
//   };
// };
// export const selectAllServicers = (
//   servicersArr: string[],
//   isChecked: boolean
// ) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SELECT_ALL_SERVICERS,
//       servicersArr,
//       isChecked
//     });
//   };
// };
// export const setViewBundle = (val: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_VIEW_BUNDLE,
//       payload: val
//     });
//   };
// };

// export const setIsLoading = (val: boolean) => {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_IS_LOADING,
//       payload: val
//     });
// };

// export const clearPandSsorting = (val: boolean) => {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_CLEAR_P_AND_S_SORTING,
//       payload: val
//     });
// };

// export const setFiltersPandS = (val: any) => {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_P_AND_S_FILTER,
//       payload: val
//     });
// };

export const updateSettlementFields = (bundleId: string, payload: any) => {
  const url = `${getConfig().apiUrl}/bundles/${bundleId}/settlementFields`;
  return publicClient.patch(url, payload);
};

export const getCustomerData = (partyIds: string[]) => {
  // eslint-disable-next-line no-shadow
  return new Promise((resolve, reject) => {
    // const pLen: number = partyIds.length;
    // const partyIdsQ: string[] = partyIds
    //   .filter((item: any) => uuidValidate(item))
    //   .map((item: string) => {
    //     return `partyIds=${item}`;
    //   });
    // if (!partyIdsQ.length) {
    //   resolve({});
    //   return;
    // }
    // if (pLen === 1) partyIdsQ.unshift(partyIdsQ[0]);
    const config = getConfig();
    const url: string = `${config.apiUrl}/customer/fetch`;
    publicClient
      .post(url, { partyIds })
      .then((res: any) => {
        const customerList: any[] = res.data || [];
        if (!customerList.length) resolve({});
        const customerListObj: any = {};
        customerList.forEach((item: any, index: number) => {
          customerListObj[item.partyId] = item;
          if (index === customerList.length - 1) resolve(customerListObj);
        });
      })
      .catch((err: any) => resolve({}));
  });
};

export const sortTrigger = (
  bundleInfo: any,
  queryPath: string,
  order: string,
  dispatch: any
) => {
  return async () => {
    dispatch(showLoader());
    const isLAT: boolean = isLATUser();
    const config = getConfig();
    const url = `${config.apiUrl}/aggregate/bundles/${bundleInfo.bundleId}/loans/fetch`;
    let reqQueryPath: string = "";
    if (queryPath.includes("firstPaymentDateOfLoan")) {
      reqQueryPath = String(queryPath).replace(/\?/g, "");
    } else {
      reqQueryPath = `${queryPath}.raw`.replace(/\?/g, "");
    }
    const reqBody: any = {
      projections: ["*"],
      sort: [{ [reqQueryPath]: order }, "_score"],
      partyIds:
        !isLAT && !isRole(UserRole.LAT_TREASURER)
          ? [getCookie("org_id") || ""]
          : [],
      limit: 0,
      offest: 0
    };
    const response: any = await new Promise((resolve, reject) => {
      publicClient
        .post(url, reqBody)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve({});
        });
    });
    const { hits } = response?.data?.response?.hits || {};
    if (!hits?.length) {
      dispatch(hideLoader());
      return;
    }
    try {
      // let bundleloansDetailsObj: any = {};
      // activeBundle.bundleLoanDetails.forEach((item: any) => {
      //   bundleloansDetailsObj[item._id] = item;
      // });
      // dispatch({
      //   type: ACTIVE_BUNDLE,
      //   payload: {
      //     ...activeBundle,
      //     bundleLoanDetails: hits.map((item: any) => {
      //       return bundleloansDetailsObj[item._id];
      //     })
      //   }
      // });
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err, "sorting");
    }
  };
};

export interface BundleReQTerms {
  bundleStatus: string[];
  originators: string[];
  loanTypes: string[];
  bundleDateRange?: any;
  fundingType: string[];
  warehouseFundType?: string[];
  takeoutPartner?: string[];
  isNoneWarehouseFunded?: boolean;
}

const reqBodyToGetBundleList = (
  mustAndMustNotterms: BundleReQTerms,
  originatorId: string,
  loanId: string,
  size: number = 10,
  from: number = 0,
  isLATTreasurer: boolean
) => {
  let must: any = [];
  if (mustAndMustNotterms.bundleStatus?.length) {
    must.push({
      terms: {
        "bundleStatus.raw": mustAndMustNotterms.bundleStatus // .map((item: any) => item.key)
      }
    });
  }
  if (
    mustAndMustNotterms.bundleDateRange &&
    "range" in mustAndMustNotterms.bundleDateRange
  ) {
    must.push(mustAndMustNotterms.bundleDateRange);
  }
  const must_not: any = [];
  if (mustAndMustNotterms?.loanTypes?.length) {
    must.push({
      terms: {
        "loanType.raw": mustAndMustNotterms.loanTypes
      }
    });
  }
  if (mustAndMustNotterms?.takeoutPartner?.length) {
    must.push({
      terms: {
        "takeoutPartner.raw": mustAndMustNotterms.takeoutPartner
      }
    });
  }
  if (
    mustAndMustNotterms?.fundingType?.length &&
    mustAndMustNotterms?.warehouseFundType?.length
  ) {
    must.push({
      bool: {
        should: [
          {
            terms: {
              "fundingType.raw": mustAndMustNotterms.fundingType
            }
          },
          {
            terms: {
              isWarehouseFunded: mustAndMustNotterms?.isNoneWarehouseFunded
                ? [false]
                : [true]
            }
          }
        ]
      }
    });
    if (mustAndMustNotterms?.isNoneWarehouseFunded) {
      must = must.map((queryItem: ObjectType) => {
        if (queryItem.bool) {
          queryItem.bool.should.push({
            bool: {
              must_not: [
                {
                  exists: {
                    field: "isWarehouseFunded"
                  }
                }
              ]
            }
          });
        }
        return queryItem;
      });
    }
  } else {
    if (mustAndMustNotterms?.fundingType?.length) {
      must.push({
        terms: {
          "fundingType.raw": mustAndMustNotterms.fundingType
        }
      });
    }
    if (mustAndMustNotterms?.warehouseFundType?.length) {
      must.push({
        bool: {
          should: [
            {
              terms: {
                isWarehouseFunded: mustAndMustNotterms?.isNoneWarehouseFunded
                  ? [false]
                  : [true]
              }
            }
          ]
        }
      });
    }
    if (mustAndMustNotterms?.isNoneWarehouseFunded) {
      must = must.map((queryItem: ObjectType) => {
        if (queryItem.bool) {
          queryItem.bool.should.push({
            bool: {
              must_not: [
                {
                  exists: {
                    field: "isWarehouseFunded"
                  }
                }
              ]
            }
          });
        }
        return queryItem;
      });
    }
  }

  if (isRoleBridge()) {
    must_not.push({
      terms: {
        "loanType.raw": ["30 Year Loan"]
      }
    });
  } else if (isRoleDSCR()) {
    must_not.push({
      terms: {
        "loanType.raw": ["Bridge Loan"]
      }
    });
  }
  if (loanId) {
    must.push({
      bool: {
        should: [
          {
            nested: {
              path: "loans",
              query: {
                bool: {
                  should: [
                    {
                      terms: {
                        "loans.loanId.raw": [loanId]
                      }
                    },
                    {
                      terms: {
                        "loans.primaryLoanId.raw": [loanId]
                      }
                    }
                  ]
                }
              }
            }
          },
          {
            term: {
              "bundleId.raw": {
                value: loanId
              }
            }
          }
        ]
      }
    });
  }
  const orgSelected: any[] = mustAndMustNotterms?.originators
    ? mustAndMustNotterms.originators.filter((itm) => itm !== "selectAll")
    : [];
  if (orgSelected.length) {
    must.push({
      terms: {
        "originatorPartyId.raw": orgSelected
      }
    });
  } else if (!isLATUser() && originatorId) {
    must.push({
      term: {
        "originatorPartyId.raw": {
          value: originatorId
        }
      }
    });
  } else if (isLATUser() && !isLATTreasurer) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    must.push({
      terms: {
        "originatorPartyId.raw": accountIds
      }
    });
  }
  return {
    query: {
      bool: {
        must,

        must_not
      }
    },
    _source: ["*"],
    sort: [
      {
        // "bundleId.raw": "asc"

        created_on: "DESC"
      },
      "_score"
    ],
    size,
    from
  };
};

const reqBodyToGetBundleStatuses = (
  loanTypes: any[],
  fundingType: any[],
  originatorId: string,
  isLATTreasurer: boolean,
  dateRange: any,
  mustOriginators: any[],
  isLAT: boolean,
  warehouseFundType?: string,
  isNoneWarehouseFunded?: boolean
) => {
  const defaultQuery: any = {
    size: 0,
    aggs: {
      originatorPartyId: {
        terms: {
          field: "originatorPartyId.raw",
          size: 500
        }
      },
      bundleStatus: {
        terms: {
          field: "bundleStatus.raw"
        }
      }
    },
    query: {}
  };
  let must: any = [];
  if (dateRange) {
    must.push(dateRange);
  }
  // if (mustOriginators.length) {
  //   must.push({
  //     terms: {
  //       "originatorPartyId.raw": mustOriginators
  //     }
  //   });
  // } else
  if (originatorId) {
    must.push({
      term: {
        "originatorPartyId.raw": {
          value: originatorId
        }
      }
    });
  } else if (isLAT && !isLATTreasurer) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    must.push({
      terms: {
        "originatorPartyId.raw": accountIds
      }
    });
  }
  const must_not: any = [];
  if (isRoleBridge()) {
    must_not.push({
      terms: {
        "loanType.raw": ["30 Year Loan"]
      }
    });
  } else if (isRoleDSCR()) {
    must_not.push({
      terms: {
        "loanType.raw": ["Bridge Loan"]
      }
    });
  }
  if (loanTypes.length) {
    must.push({
      terms: {
        "loanType.raw": loanTypes
      }
    });
  }
  if (fundingType.length && warehouseFundType?.length) {
    must.push({
      bool: {
        should: [
          {
            terms: {
              "fundingType.raw": fundingType
            }
          },
          {
            terms: {
              isWarehouseFunded: isNoneWarehouseFunded ? [false] : [true]
            }
          }
        ]
      }
    });
    if (isNoneWarehouseFunded) {
      must = must.map((queryItem: ObjectType) => {
        if (queryItem.bool) {
          queryItem.bool.should.push({
            bool: {
              must_not: [
                {
                  exists: {
                    field: "isWarehouseFunded"
                  }
                }
              ]
            }
          });
        }
        return queryItem;
      });
    }
  } else {
    if (fundingType.length) {
      must.push({
        terms: {
          "fundingType.raw": fundingType
        }
      });
    }
    if (warehouseFundType?.length) {
      must.push({
        bool: {
          should: [
            {
              terms: {
                isWarehouseFunded: isNoneWarehouseFunded ? [false] : [true]
              }
            }
          ]
        }
      });
    }
    if (isNoneWarehouseFunded) {
      must = must.map((queryItem: ObjectType) => {
        if (queryItem.bool) {
          queryItem.bool.should.push({
            bool: {
              must_not: [
                {
                  exists: {
                    field: "isWarehouseFunded"
                  }
                }
              ]
            }
          });
        }
        return queryItem;
      });
    }
  }
  defaultQuery.query = {
    ...defaultQuery.query,
    bool: {
      must,
      must_not
    }
  };
  return defaultQuery;
};

export function getBundles(body: any) {
  const config = getConfig();
  const url = `${config.apiUrl}/search/${config.prefix}_bundle/_search`;
  // const url = `${getConfig().apiUrl}/search/dev_bundle_01092020/_search`;
  const header = {
    headers: {
      // "ignore-auth": true,
      "Content-Type": "application/json" // ,
      // Authorization: `Bearer ${getCookie("id_token")}`
    }
  };
  return publicClient.post(url, body, header);
}

export function getFilterStatusList(
  savedFilterProps: any,
  dateRange: any,
  staticBundleStatus: any,
  orgFilterCheckList: any,
  mustOriginators: any[],
  originatorId: string,
  loanTypes: any[],
  fundingType: any[],
  warehouseFundType: any,
  isLATTreasurer: boolean,
  callBundleList: boolean = false,
  isNoneWarehouseFunded?: boolean
) {
  return async (dispatch: any) => {
    try {
      const isLAT = isLATUser();
      const body = reqBodyToGetBundleStatuses(
        loanTypes,
        fundingType,
        originatorId,
        isLATTreasurer,
        dateRange,
        mustOriginators,
        isLAT,
        warehouseFundType,
        isNoneWarehouseFunded
      );
      const response = await getBundles(body);
      const { bundleStatus, originatorPartyId } =
        response.data.response.aggregations;
      const originatorPartyIds: any = originatorPartyId.buckets.map(
        (item: any) => item.key
      );
      const originatorPartyIdsObj: any = originatorPartyIds.length
        ? await getCustomerData(originatorPartyIds)
        : {};
      const orgAggsBucket: any[] = [];
      originatorPartyId.buckets.forEach((item: any) => {
        if (item.key in originatorPartyIdsObj)
          orgAggsBucket.push({
            ...item,
            value: item.key,
            label: originatorPartyIdsObj[item.key]?.accountName,
            checked: orgFilterCheckList[item.key]?.checked || false
          });
      });
      if (orgAggsBucket.length > 1)
        orgAggsBucket.sort((a: any, b: any) => a.label.localeCompare(b.label));
      let orgAggsBucketNew: any[] = [
        {
          label: "Select All",
          value: "selectAll",
          key: "selectAll",
          checked: orgFilterCheckList.selectAll?.checked || false
        },
        ...orgAggsBucket
      ];
      if (
        savedFilterProps?.isFilterRet &&
        savedFilterProps?.orgFilters?.length
      ) {
        const fOS: any = {};
        savedFilterProps.orgFilters.forEach((itm: any) => {
          fOS[itm.value] = itm;
        });
        orgAggsBucketNew = orgAggsBucketNew.map((itm: any) => {
          return {
            ...itm,
            checked: fOS[itm.value]?.checked || false
          };
        });
      }
      // dispatch({
      //   type: GET_FILTER_ORG_LIST,
      //   payload: orgAggsBucketNew
      // });
      dispatch(setOrgFilter(orgAggsBucketNew));
      const filterObj: any = {};

      bundleStatus.buckets.forEach((item: any) => {
        let tKey: string = item.key;
        let tItem: any = item;
        if (!isLAT && tKey in maskValIfOrg) {
          tKey = maskValIfOrg[tKey]; // mask status for orginator
          tItem = {
            ...item,
            key: tKey
          };
        }
        filterObj[tKey] = tItem;
      });
      // mapping filter list
      const payloadData: any[] = Object.entries(staticBundleStatus).map(
        ([key, value]: any) => {
          let docCount = filterObj[key]?.doc_count || 0;
          if (key === BundleStatuses.LDC && BundleStatuses.SPAG in filterObj)
            docCount += filterObj[BundleStatuses.SPAG]?.doc_count || 0;
          if (key in filterObj) {
            return {
              ...value,
              docCount
            };
          }
          return {
            ...value,
            docCount: value.value === "selectAll" ? null : 0
          };
        }
      );
      // dispatch({
      //   type: GET_FILTER_STATUS_LIST,
      //   payload: payloadData
      // });
      // if (callBundleList)
      //   dispatch({
      //     type: TRIGGER_BUNDLE_LISTING_ONCE,
      //     payload: true
      //   });
      dispatch(setFilterStatusList(payloadData));

      if (callBundleList) {
        dispatch(triggerBundleListingOnce(true));
      }
    } catch (err) {
      console.log("error in exception", err);
    }
  };
}

// export function setBundleLIstCustomerData(newData: any, oldData: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_BUNDLE_CUSTOMER_DATA,
//       payload: { ...oldData, ...newData }
//     });
//   };
// }

export function getBundleListSettlement(
  mustAndMustNotterms: BundleReQTerms,
  originatorId: string = "",
  loanId: string = "",
  pageNumber: number = 0,
  presentList: any[] = [],
  isLATTreasurer: boolean,
  sCallBack?: () => void,
  fCallBack?: () => void
) {
  return async (dispatch: any) => {
    // console.log(pageNumber, "searching page");
    dispatch(showLoader());
    try {
      const body = reqBodyToGetBundleList(
        mustAndMustNotterms,
        originatorId.trim(),
        loanId.trim(),
        5,
        pageNumber * 5,
        isLATTreasurer
      );
      const isLAT = isLATUser();
      const response = await getBundles(body);
      const { hits, total } = response.data.response.hits;
      const partyIds: any = [];
      let newBundleList: any[] = hits.map((item: any, index: number) => {
        const {
          loans,
          loanType,
          bundleStatus,
          createdByInfo,

          created_by,
          originatorPartyIdInfo,
          originatorPartyId,
          bundleId,
          closingDate,

          created_on,
          bundleSellerMapping,
          isWarehouseFunded,
          fundingType,
          takeoutPartner,
          isAASigned
        } = item._source || {};
        if (!createdByInfo) partyIds.push(created_by);
        if (!originatorPartyIdInfo) partyIds.push(originatorPartyId);
        let loanTypeName = "";
        if (loanType === "30 Year Loan") {
          loanTypeName = "DSCR";
        } else if (loanType === "Bridge Loan") {
          loanTypeName = "Bridge";
        }
        const loanTypeFormat: any = `${loans.length} ${loanTypeName} ${
          loans.length > 1 ? "Loans" : "Loan"
        }`;
        let bundleStatusF: any = bundleStatus;
        if (!isLAT && bundleStatus in maskValIfOrg)
          bundleStatusF = maskValIfOrg[bundleStatus];
        return {
          bundleId,
          bundleStatusF,
          loanTypeFormat,
          originatorName: originatorPartyIdInfo?.accountName,
          closingDate,
          loans,
          loanType,
          bundleStatus,
          createdByInfo,

          created_by,
          originatorPartyId,

          created_on,
          bundleSellerMapping,
          isWarehouseFunded,
          fundingType,
          takeoutPartner,
          isAASigned
        };
      });
      // let partyIdObj: any = {};
      if (partyIds.length) {
        const partyIdObj: any = await getCustomerData(partyIds);
        newBundleList = newBundleList.map((item: any) => {
          const {
            createdByInfo,
            created_by,
            originatorPartyIdInfo,
            originatorPartyId
          } = item || {};
          const addData: any = {};
          if (!createdByInfo && created_by in partyIdObj) {
            addData.createdByInfo = partyIdObj[created_by];
          }
          if (!originatorPartyIdInfo && originatorPartyId in partyIdObj) {
            addData.originatorName = partyIdObj[originatorPartyId]?.accountName;
          }
          if (Object.keys(addData).length) {
            return {
              ...item,
              ...addData
            };
          }
          return item;
        });
      }
      const newList =
        pageNumber !== 0 ? presentList.concat(newBundleList) : newBundleList;
      // dispatch({
      //   type: SET_BUNDLE_LIST,
      //   payload: newList
      // });
      // dispatch({
      //   type: SET_TOTAL_BUNDLE_COUNT,
      //   payload: total.value
      // });
      dispatch(setBundleList(newList));
      dispatch(setTotalBundleCount(total.value));
      dispatch(hideLoader());
      if (sCallBack) sCallBack();
    } catch (err) {
      console.error(err);
      if (fCallBack) fCallBack();
      dispatch(hideLoader());
    }
  };
}

// export function setChoosenBundleCount(count: number) {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_TOTAL_BUNDLE_COUNT,
//       payload: count
//     });
// }

export function addLoanBundle(
  comment: string,
  purchaseProps: any,
  bundleStatus: string
) {
  const {
    loanIds,
    originatorPartyId,
    loanType,
    fundingType,
    isWarehouseFunded,
    closingDate
  } = purchaseProps;
  const bundleObj: any = {
    originatorPartyId,
    loanType,
    bundleStatus,
    loanIds,
    closingDate
  };
  if (isWarehouseFunded) bundleObj.isWarehouseFunded = isWarehouseFunded;
  if (fundingType) bundleObj.fundingType = fundingType;
  const url = `${getConfig().apiUrl}/aggregate/bundles`;
  return publicClient.post(url, bundleObj);
}

// export function setTabView(value: number) {
//   return (disaptch: any) => disaptch(setTabViewRedux(value));
// }

// export function setAlertOrg(value: boolean) {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_ORG_WARN_ALERT,
//       payload: value
//     });
// }

/**
 * This is used to notify the user that the bundle is updated (async process of calling rules when the loan inputs are edited)
 * This function will checks the status of each loan in the bundle. If the status is in-progress for any loan in bundle, sets few variables in reducer to start polling */
export function setPollingForRefresh(
  loanDetailStatus: string,
  pollingData?: { n: number; pollingCall: boolean },
  dispatch?: any
) {
  const pollingRefresh = async () => {
    const anyLoanUpdated: boolean =
      !pollingData || pollingData?.n <= 5
        ? loanDetailStatus === "IN_PROGRESS"
        : false;
    if (anyLoanUpdated) {
      // give enough time for active bundle to be set in first call
      setTimeout(
        () => {
          // dispatch({
          //   type: UPDATE_POLLING_DATA,
          //   payload: {
          //     startPolling: true,
          //     timeInMs: pollingData?.n || 0
          //   }
          // });
          dispatch(
            updatePollingData({
              startPolling: true,
              timeInMs: pollingData?.n || 0
            })
          );
        },
        !pollingData?.n ? 4000 : 0
      );
    } else {
      // dispatch({
      //   type: UPDATE_POLLING_DATA,
      //   payload: {
      //     startPolling: false,
      //     timeInMs: new Date().getTime()
      //   }
      // });
      dispatch(
        updatePollingData({
          startPolling: false,
          timeInMs: new Date().getTime()
        })
      );
    }
  };
  pollingRefresh();
}

// export function refreshPage() {
//   return async (dispatch: any) => {
//     dispatch({ type: SET_REFRESH });
//   };
// }

// export function clearPolling() {
//   return async (dispatch: any) => {
//     dispatch({ type: CLEAR_REFRESH });
//   };
// }

export const startBundlePolling = (dispatch: any) => {
  const startPolling = () => {
    // dispatch({
    //   type: UPDATE_POLLING_DATA,
    //   payload: {
    //     startPolling: true,
    //     timeInMs: 0
    //   }
    // });
    dispatch(
      updatePollingData({
        startPolling: true,
        timeInMs: 0
      })
    );
  };
  startPolling();
};

export const getLoanAuditInBulk = (loansList: any[]) => {
  const url: string = `${getConfig().apiUrl}/aggregate/auditservice/loans`;
  return new Promise((resolve) => {
    publicClient
      .post(url, loansList)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        console.error(err);
        resolve({});
      });
  });
};

export const getOnlyBundleHistory = (bundleId: string) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/auditservice/bundles/${bundleId}`;
  return new Promise((resolve) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        console.error(err);
        resolve({});
      });
  });
};

const getBundleData = (bundleId: any) => {
  const partyIds: any[] =
    !isLATUser() && !isRole(UserRole.LAT_TREASURER)
      ? [getCookie("org_id") || ""]
      : [];
  const must: any =
    partyIds && partyIds.length
      ? [
          {
            term: {
              "bundle.bundleId.raw": {
                value: bundleId
              }
            }
          },
          {
            terms: {
              "loan.loanDetailId.originatorPartyId.raw": partyIds
            }
          }
        ]
      : [
          {
            term: {
              "bundle.bundleId.raw": {
                value: bundleId
              }
            }
          }
        ];
  const reqBody: any = {
    _source: [
      "bundle.bundleId",
      "bundle.bundleStatus",
      "bundle.closingDate",
      "bundle.loanType",
      // "bundle.loans",
      "loan.loanInfo.primaryLoanId",
      "loan.loanInfo.firstPaymentDateOfLoan",
      "loan.loanInfo.toorakProduct",
      "loan.loanInfo.loanPurpose",
      "loan.loanInfo.originationDate",
      "loan.loanInfo.loanStructure",
      "loan.loanDetailId.loanId",
      "loan.loanDetailId.loanSummaryId.updatedTaxEscrowBalance",
      "loan.loanDetailId.loanSummaryId.updatedInsuranceEscrowBalance",
      "loan.loanDetailId.loanSummaryId.escrowTaxes",
      "loan.loanDetailId.loanSummaryId.escrowInsurance",
      "loan.loanDetailId.originatorPartyId",
      "loan.loanEconomics.originalLoanAmount",
      "loan.loanEconomics.financedInterestReserve",
      "loan.loanEconomics.totalOriginationAndDiscountPoints",
      "loan.loanEconomics.interestRateAsOfCutOffDate",
      "loan.loanEconomics.cutOffDateLoanAmount",
      "loan.loanEconomics.cutOffDateMaximumLoanAmount",
      "loan.loanEconomics.prePaymentPenaltyMonths",
      "loan.loanEconomics.interestOnlyPeriod",
      "loan.loanEconomics.dscr",
      "loan.loanEconomics.currentLoanBalance",
      "loan.loanEconomics.totalBudgetAmount",
      "loan.loanCredit.phase1ClosingDate",
      "loan.loanCredit.phase1ClosingDatePayment",
      "loan.loanCredit.phase1HoldbackAmount",
      "loan.loanCredit.phase1HoldbackPercentage",
      "loan.loanUserMap",
      "loan.loanStage",
      "loan.loanType",
      "loan.loanTypeId",
      "guarantors",
      "borrowers",
      "bundle.fundingType",
      "originator.accountName",
      "originator.partyId",
      "originator.sellerName",
      "properties",
      "result.loanResult.firstLoss",
      "result.loanResult.toorakInterests",
      "result.loanResult.settlementResults",
      "result.loanResult.loanPricing",
      "result.loanResult.loanEconomics",
      "result.loanResult.loanFeatures"
    ],
    query: {
      bool: {
        must
      }
    },
    from: 0,
    sort: [{ "loan.loanDetailId.loanId.raw": "asc" }, "_score"],
    size: 500
  };
  const headers: any = {
    Authorization: `Bearer ${getCookie("id_token")}`
  };
  return fetchSearchResultsLoanAgg(reqBody, headers);
};

// export function setBundleSelectedLoans(loans: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_BUNDLE_SELECTED_LOANS,
//       payload: loans
//     });
// }

/**
 *
 * @param loanIds List of loanIds used in bundle creation
 */

export const updateLoanStatus = (
  loanIds: string[],
  status: LoanStatusEnum,
  dispatch: any,
  fundingType?: string
) => {
  const callBack = () => {
    Promise.all(
      loanIds.map(async (loanId) => {
        const lonStatusUpdate = dispatchAndUpdate(
          loanId,
          status,
          fundingType === TABLE_FUNDING ? LoanStage.pre : LoanStage.post,
          dispatch
        );
        return lonStatusUpdate(dispatch);
      })
    );
  };
  callBack();
};

export function removeLoansFromBundles(
  deleteloans: any,
  bundleId: string,
  deleteBundle: boolean,
  failureCallback: any,
  successCallback: any
) {
  if (!deleteloans.length) {
    failureCallback("noLoans");
    return;
  }
  const url = `${getConfig().apiUrl}/aggregate/bundles/${bundleId}/loans`;
  const body: any[] = deleteloans.map((item: any) => item.loanId);
  publicClient
    .delete(url, { data: body })
    .then((res: any) => {
      if (deleteBundle) {
        publicClient
          .delete(`${getConfig().apiUrl}/bundles/${bundleId}`)
          .then((res2: any) => successCallback(deleteloans, deleteBundle))
          .catch((_err2: any) => {
            failureCallback("error");
          });
      } else successCallback(deleteloans);
    })
    .catch((_err: any) => {
      failureCallback("error");
    });
}

// export function setSearchedLoanId(loanId: string) {
//   return (dispatch: any) => dispatch(setSearchedLoanID(loanId));
// }

// export function setbundleList(bundles: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_BUNDLE_LIST,
//       payload: bundles
//     });
// }

// export function requestSelectedLoans(val: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: SET_SELECTED_BUNLDE_LOANS,
//       payload: val
//     });
// }
// export function triggerBundleListingOnce(val: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: TRIGGER_BUNDLE_LISTING_ONCE,
//       payload: val
//     });
// }

// export function resetPageNumber(val: boolean) {
//   return (dispatch: any) =>
//     dispatch({
//       type: RESET_PAGE_NUMBER,
//       payload: val
//     });
// }

export function submitLoanDrawDetails(
  bundleId: string,
  loanId: string,
  drawAvailData: any
) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/bundles/${bundleId}/loans/${loanId}/drawDetails`;
    // resolve();
    publicClient
      .post(url, drawAvailData)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.error(err);
        reject();
      });
  });
}

export function updateDrawDetails(
  bundleId: string,
  loanId: string,
  drawAvailData: any,
  drawId: string
) {
  return new Promise((resolve, reject) => {
    const url = `${
      getConfig().apiUrl
    }/bundles/${bundleId}/loans/${loanId}/drawDetails/${drawId}`;
    const headers: any = {
      // "ignore-auth": true,
      // Authorization: `Bearer ${getCookie("id_token")}`
    };
    const body = {
      drawDate: drawAvailData.drawDate,
      drawAmount: drawAvailData.drawAmount.replace("$", "").replace(/,/g, "")
    };
    publicClient
      .patch(url, body, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.error(err);
        reject();
      });
  });
}

export function treasuryUpdateThirdPartyServicers(
  bundleId: string,
  servicerArr: Array<string> = []
) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/bundles/${bundleId}/settlementFields`;
    const headers: any = {
      // "ignore-auth": true,
      // Authorization: `Bearer ${getCookie("id_token")}`
    };
    const body = {
      loans: servicerArr
    };
    publicClient
      .patch(url, body, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.error(err);
        reject();
      });
  });
}

export function setThirdPartyServicers(
  bundleId: string,
  servicerArr: Array<string> = [],
  isThirdPartyServiced: boolean = false
) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/bundles/${bundleId}/settlementFields`;
    const headers: any = {
      // "ignore-auth": true,
      // Authorization: `Bearer ${getCookie("id_token")}`
    };
    const thirdPartyArray: any = [];
    servicerArr.forEach((servicer: any) => {
      thirdPartyArray.push({ loanId: servicer, isThirdPartyServiced });
    });

    const body = {
      loans: thirdPartyArray
    };
    publicClient
      .patch(url, body, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        console.error(err);
        reject();
      });
  });
}

export function updateBundleLoanStatus(
  loanStage: string,
  status: string,
  loanId: string,
  bundleId: string
) {
  const url = `${getConfig().apiUrl}/bundles/${bundleId}/loans/${loanId}`;
  const payload = {
    loanStage,
    status
  };
  return publicClient.patch(url, payload);
}

export function updateLoanLevelStatus(
  bundleId: string,
  loanIds: string[],
  status: string
) {
  const url = `${
    getConfig().apiUrl
  }/bundles/${bundleId}/loanBundleStatus?status=${encodeURIComponent(status)}`;
  const headers: any = {
    // "ignore-auth": true,
    // Authorization: `Bearer ${getCookie("id_token")}`
  };
  const payload = {
    loanIds
  };
  return publicClient.patch(url, payload, { headers });
}

export function updateLoanBundleStatus(
  bundleId: string,
  loanData: any[],
  loanIds: string[],
  loanBundleStatus: string
) {
  // updating bundle list with updated status
  const newLoanData: any[] = loanData.map((itm: any) => {
    if (loanIds.indexOf(itm.loanId) > -1) {
      return {
        ...itm,
        loanBundleStatus
      };
    }
    return itm;
  });
  const isRemaining = newLoanData.filter(
    (item: any) => item.loanBundleStatus !== loanBundleStatus
  );
  // if all loans are updated then bundle level status is updated.
  if (!isRemaining.length) {
    updateBundleFields(bundleId, {
      bundleStatus: loanBundleStatus
    }).catch((err) => {
      console.error(err);
    });
  }
}

export function updateBundleFields(
  bundleId: string,
  payload: any,
  parameters?: string
) {
  let url = `${getConfig().apiUrl}/aggregate/bundles/${bundleId}`;
  if (parameters) url = `${url}?${parameters}`;
  return new Promise((resolve) => {
    publicClient
      .patch(url, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        resolve(err.response || {});
      });
  });
}

export function getBundleFields(bundleId: string) {
  const url = `${getConfig().apiUrl}/aggregate/bundles/${bundleId}`;
  const headers: any = {
    // "ignore-auth": true
  };
  return new Promise((resolve) => {
    publicClient
      .get(url, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        resolve({});
      });
  });
}

export function getAggBundleFields(bundleId: string) {
  const url = `${getConfig().apiUrl}/aggregate/bundles/${bundleId}`;
  const headers: any = {
    // "ignore-auth": true
  };
  return new Promise((resolve) => {
    publicClient
      .get(url, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        resolve({});
      });
  });
}

// export function resetLoanSelections(val: boolean) {
//   return (dispatch: any) => {
//     dispatch({
//       type: RESET_LOANS_SELECTIONS,
//       payload: val
//     });
//   };
// }

// export function deselectActiveBundle() {
//   return (dispatch: any) => {
//     dispatch({
//       type: ACTIVE_BUNDLE,
//       payload: {
//         bundleId: "",
//         loanType: "",
//         bundleStatus: "",
//         bundleLoanDetails: [],
//         bundleIndex: 0
//       }
//     });
//   };
// }

// export function setLoanTableToExport(
//   bundleId: string,
//   bundleStatus: string,
//   loanTable: any
// ) {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_LOAN_TABLE_TO_EXPORT,
//       payload: {
//         bundleId,
//         bundleStatus,
//         loanTable
//       }
//     });
//   };
// }

export function addTrackingNumbers(bundleId: string, trackingDetails: any) {
  const url = `${getConfig().apiUrl}/bundles/${bundleId}/documents`;
  const addTracks: any[] = [];
  const updateTracks: any[] = [];
  Object.keys(trackingDetails).forEach((loanId: string) => {
    if (!trackingDetails[loanId]?.documentId)
      addTracks.push({
        loanId,
        trackingNumber: trackingDetails[loanId]?.trackingNumber,
        documentType: custodyDocuments.documentType
      });
    else if (trackingDetails[loanId]?.edited)
      updateTracks.push({
        trackingNumber: trackingDetails[loanId]?.trackingNumber,
        documentId: trackingDetails[loanId]?.documentId
      });
  });
  if (updateTracks.length)
    return publicClient.patch(url, { documentDetails: updateTracks });
  if (addTracks.length) return publicClient.post(url, addTracks);
  return new Promise((resolve, reject) => {
    throw new Error("noData");
  });
}

// export function setBundleInfo(activeData: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: ACTIVE_BUNDLE,
//       payload: activeData
//     });
//   };
// }

// export function updateSigneeBundleInfo(newData: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_NEW_SIGNEE_ACTIVE_BUNDLE,
//       payload: newData
//     });
//   };
// }

// export function setFilterStatusList(newFilterStatusList: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: GET_FILTER_STATUS_LIST,
//       payload: newFilterStatusList
//     });
// }

// export function setLoanTypeFilter(newList: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: GET_FILTER_LOAN_TYPE_LIST,
//       payload: newList
//     });
// }
// export function setTakeoutPartnerFilter(newList: any) {
//   return (dispatch: any) =>
//     dispatch({
//       type: GET_TAKEOUTPARTNER_LIST,
//       payload: newList
//     });
// }

export const generateSettlement = (
  bundleId: string,
  addOverridden: boolean
) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/generateSettlement?addOverridden=${addOverridden}`;
  return publicClient.post(url, null);
};
export interface BundleInfoGen {
  bundleId: string;
  closingDate?: any;
  bundleStatus: BundleStatuses;
  loanIds: any[];
}
export function submitGenerateSettlement(
  bundleInfo: BundleInfoGen,
  successCallBack: any,
  errorCallback: any,
  addOverridden: boolean = false
) {
  const { bundleId, closingDate, bundleStatus, loanIds } = bundleInfo;
  return async () => {
    if (closingDate) await updateBundleFields(bundleId, { closingDate });
    generateSettlement(bundleId, addOverridden)
      .then((res1: any) => {
        if (
          bundleStatus !== BundleStatuses.SPAG &&
          staticBundleStatusList.indexOf(bundleStatus) <= 6
        ) {
          updateBundleFields(bundleId, {
            bundleStatus: BundleStatuses.SPAG
          }).then(() => {
            if (loanIds?.length)
              updateLoanLevelStatus(bundleId, loanIds, BundleStatuses.LDC)
                .then((upRes: any) => {
                  console.log("updated", upRes);
                  // can update loan level status to reducer but not required
                })
                .catch((err: any) => {
                  console.error(err);
                });
          });
        }
        successCallBack();
      })
      .catch((err1: any) => {
        console.error(err1);
        errorCallback();
      });
  };
}

export function getCalculetedFields(
  bundleId: string,
  takeoutPartner: string | null | undefined
) {
  // return (dispatch: any) => {
  let url = `${
    getConfig().apiUrl
  }/evaluation/v2/${bundleId}/partner/results?loanStage=APPROVED`;
  if (takeoutPartner) {
    url += `&partnerId=${takeoutPartner}`;
  }
  return publicClient.get(url);
  // .then((res: any) => {
  //   dispatch({
  //     type: CALCULATED_BUNDLE_FIELDS,
  //     payload: res.data
  //   });
  // })
  // .catch((err: any) => {
  //   console.error(err);
  // });
  // };
}

export function getServicerOptions(reqBody: any) {
  const url = `${getConfig().apiUrl}/aggregate/custom/SERVICER/values/fetch`;
  return new Promise((resolve) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch((_err) => {
        resolve({});
      });
  });
}

export function getCustodianOptions(reqBody: any) {
  const url = `${getConfig().apiUrl}/custom/CUSTODIAN/values/fetch`;
  return new Promise((resolve) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch((_err) => {
        resolve({});
      });
  });
}

export function getWareHouseLenderOptions(reqBody: any) {
  return new Promise((resolve) => {
    getSigneeUsers(reqBody.userId).then((resp: any) => {
      const whlData: ObjectType[] = [];
      const { customers = [], accountmap = {} } = resp.data;
      const wareHouseLenderList: ObjectType[] = [];
      accountmap.sellers.forEach((seller: ObjectType) => {
        wareHouseLenderList.push(...seller.warehouseLenders);
      });
      const warehouseLenderMap = new Map(
        wareHouseLenderList.map((whl) => [whl.partyId, whl])
      );
      customers.forEach((customer: ObjectType) => {
        const whl = warehouseLenderMap.get(customer.partyId);
        const whlLoanTypes = whl?.loanTypes;
        if (whl && whlLoanTypes?.includes(reqBody.loanType)) {
          whlData.push({...customer, isHidden: !whl.isActive});
        }
      });
      resolve({ data: whlData });
    });
  });
}

// export function recallGeneratedFields(val: boolean) {
//   return (dispatch: any) => {
//     dispatch({
//       type: RECALL_CALC_FIELDS,
//       payload: val
//     });
//   };
// }

// export function storeUpdatedFieldsToRegenerate(updatedObj: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: STORE_UPDATED_FIELDS_REGENERATE,
//       payload: updatedObj
//     });
//   };
// }

export const addOverriddenFields = (
  bundleId: string,
  payload: any,
  takeoutPartner: string | null | undefined
) => {
  let urlEval = `${
    getConfig().apiUrl
  }/evaluation/v2/${bundleId}/partner/results?loanStage=APPROVED&resultType=OVERRIDDEN`;
  if (takeoutPartner) {
    urlEval += `&partnerId=${takeoutPartner}`;
  }
  // Adding overriden results to eveluation API
  return publicClient.post(urlEval, {
    loanResults: payload
  });
};

export const postLoanFieldsAggBulk = (payload: any, noSkipAudit?: boolean) => {
  let url = `${
    getConfig().apiUrl
  }/aggregate/bulk/loans/update?skipAudit=${!noSkipAudit}`;
  return publicClient.post(url, payload);
};

function createSSReGenPayload(
  loanId: string,
  editedData: any,
  partyIdInfo: any
) {
  const toBeSS_Overriden: any = { ...editedData };
  const editedDataList: any[] = Object.keys(editedData);
  if (!editedDataList.length) return {};
  let ssLoanFieldsPayload: any = {};
  let ssRulesFieldsPayload: any = {};
  const ssNonRuleFields: any = {};
  editedDataList.forEach((field: any) => {
    if (field in ssNonRuleField) {
      if (field === "drawAmount" || field === "drawDate") {
        const addData = ssNonRuleFields.drawDetails?.length
          ? ssNonRuleFields.drawDetails[0]
          : {};
        ssNonRuleFields.drawDetails = [
          {
            ...addData,
            [field]: editedData[field]
          }
        ];
      } else ssNonRuleFields[field] = editedData[field];
      delete toBeSS_Overriden[field];
    } else if (field in ssRulesFields) {
      const sField = ssRulesFieldsMask[field] || field;
      const ssLoanRes: any = ssRulesFieldsPayload?.loanResult
        ? ssRulesFieldsPayload?.loanResult[loanId]
        : {};
      ssRulesFieldsPayload = {
        ...ssRulesFieldsPayload,
        loanResult: {
          ...ssRulesFieldsPayload?.loanResult,
          [loanId]: {
            ...ssLoanRes,
            [ssRulesFields[field]]: {
              ...(ssLoanRes[ssRulesFields[field]] || {}),
              [sField]: editedData[field]
            }
          }
        }
      };
      if (field in onlyLoanFields) delete toBeSS_Overriden[field];
    } else if (field in ssLoanFields) {
      const preAdd: any = {
        [field]: editedData[field]
      };
      if (field === "servicerPartyId") {
        preAdd.servicerName = partyIdInfo[editedData[field]];
      }
      ssLoanFieldsPayload = {
        ...ssLoanFieldsPayload,
        [ssLoanFields[field]]: {
          ...ssLoanFieldsPayload[ssLoanFields[field]],
          ...preAdd
        }
      };
      if (field in onlyLoanFields) delete toBeSS_Overriden[field];
    }
  });
  return {
    toBeSS_Overriden,
    ssNonRuleFields,
    ssLoanFieldsPayload,
    ssRulesFieldsPayload
  };
}

export function regenerateSettlementProcess(
  bundleId: string,
  loanId: string,
  updatedObj: any,
  errorCallback: any,
  successCallback: any = () => console.log("success callback"),
  loanType: any,
  loanStage: string,
  partyIdInfo: any = {},
  takeoutPartner: string | null | undefined,
  dispatch: any
) {
  const callback = async () => {
    const payloadInfo: any = createSSReGenPayload(
      loanId,
      updatedObj,
      partyIdInfo
    );
    if (
      payloadInfo?.ssNonRuleFields &&
      Object.keys(payloadInfo?.ssNonRuleFields).length
    ) {
      await updateSettlementFields(bundleId, {
        loans: [
          {
            loanId,
            ...payloadInfo.ssNonRuleFields
          }
        ]
      });
    }
    if (
      payloadInfo?.toBeSS_Overriden &&
      Object.keys(payloadInfo.toBeSS_Overriden).length
    ) {
      await addOverriddenFields(
        bundleId,
        {
          [loanId]: {
            settlementResults: payloadInfo.toBeSS_Overriden
          }
        },
        takeoutPartner
      );
    }
    const aggloanBulkPayload: any = {};
    if (
      payloadInfo?.ssLoanFieldsPayload &&
      Object.keys(payloadInfo.ssLoanFieldsPayload).length
    ) {
      aggloanBulkPayload.loan = payloadInfo.ssLoanFieldsPayload;
    }
    if (
      payloadInfo?.ssRulesFieldsPayload &&
      Object.keys(payloadInfo.ssRulesFieldsPayload).length
    ) {
      aggloanBulkPayload.result = payloadInfo.ssRulesFieldsPayload;
    }
    if (Object.keys(aggloanBulkPayload).length) {
      await postLoanFieldsAggBulk([
        {
          loanId,
          loanStage,
          loanType: loanType === LoanTypes.BridgeLoan ? "bridge" : "30year",
          resultType: "OVERRIDDEN",
          syncRules: true,
          bundleId,
          bundleResultType: "OVERRIDDEN",
          bundleLoanStage: "APPROVED",
          syncSettlement: true,
          ...aggloanBulkPayload
        }
      ])
        .then((res2: any) => {
          successCallback({});
          dispatch(hideLoader());
        })
        .catch((err2: any) => {
          console.error(err2);
          dispatch(hideLoader());
          errorCallback();
        });
    } else {
      // generate settlement with edited fields in overriden object
      await generateSettlement(bundleId, true)
        .then((res2: any) => {
          successCallback({});
          dispatch(hideLoader());
        })
        .catch((err2: any) => {
          console.error(err2);
          dispatch(hideLoader());
          errorCallback();
        });
    }
  };
  callback();
}

export function updateLoanDetails(
  loanId: string,
  loanStage: LoanStage,
  loanType: any,
  loanDetailsObj: any
  // financedInterestReserve: any
): Promise<any> {
  const url = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}?loanStage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        console.log("loan details response", res);

        const reqstbody = formatResponse(res.data);
        const requestObj = getLoansRequestObj(
          loanId,
          loanType,
          reqstbody,
          loanDetailsObj
        );
        const url1 = `${getConfig().apiUrl}/aggregate/loans`;
        publicClient
          .post(url1, requestObj)
          .then((response: any) => {
            const result = JSON.parse(JSON.stringify(res.data));
            if (loanDetailsObj.financedInterestReserve)
              result.loanEconomics.financedInterestReserve =
                loanDetailsObj.financedInterestReserve;
            if (loanDetailsObj.cutOffDateLoanAmount)
              result.loanEconomics.cutOffDateLoanAmount =
                loanDetailsObj.cutOffDateLoanAmount;
            resolve(result);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch(reject);
  });
}

export function formatResponse(response: any) {
  const rqstBody = response;
  rqstBody.loanUserMap.forEach((item: any) => {
    if (!Object.keys(item?.customer).includes("accountType")) {
      // eslint-disable-next-line no-param-reassign
      item.customer.accountType = "";
    }
    if (item?.ownershipOfEntity)
      item.ownershipOfEntity = Number(item.ownershipOfEntity);
  });
  console.log("rqstBodyrqstBody", rqstBody);
  return rqstBody;
}

export function getLoansRequestObj(
  loanId: string,
  loanType: any,
  response: any,
  loanDetailsObj: any
) {
  return {
    loanId,
    loanTypeId: loanType === "30 Year Loan" ? 1 : 2,
    loanType: loanType === "30 Year Loan" ? "InvestorDSCR" : "BridgeLoan",
    loanEconomics: {
      ...loanDetailsObj
    },
    loanInfo: {
      primaryLoanId: response?.loanInfo?.primaryLoanId
    },
    loanUserMap: response.loanUserMap,
    loanConfig: {},
    loanCondominium: {}
  };
}

// export function resetUpdateGeneratedFields() {
//   return (dispatch: any) => {
//     dispatch({
//       type: STORE_UPDATED_FIELDS_REGENERATE,
//       payload: { loans: {} }
//     });
//   };
// }

export function getBundleLoanData(loanId: string, isLAT: boolean) {
  const url = `${getConfig().apiUrl}/aggregate/bundles/loans/${loanId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [404]
        })
      } as any)
      .then(async (res: any) => {
        const { bundleDetails } = res.data;
        const statusIndex: number = staticBundleStatusList.indexOf(
          bundleDetails.bundleStatus
        );
        if (isLAT && statusIndex < 2) {
          resolve({
            bundleStatus: bundleDetails.bundleStatus,
            bundleId: bundleDetails.bundleId,
            takeoutPartner: bundleDetails.takeoutPartner
          });
          return;
        }
        if (!isLAT && statusIndex < 3) {
          resolve({
            bundleStatus: bundleDetails.bundleStatus,
            bundleId: bundleDetails.bundleId,
            takeoutPartner: bundleDetails.takeoutPartner
          });
          return;
        }
        const calcFields = await getCalculetedFields(
          bundleDetails.bundleId,
          bundleDetails.takeoutPartner
        );
        resolve({
          loanbundleData: res.data,
          calcFields: calcFields.data,
          bundleStatus: bundleDetails.bundleStatus,
          bundleId: bundleDetails.bundleId,
          takeoutPartner: bundleDetails.takeoutPartner
        });
      })
      .catch(reject);
  });
}

// export function setBundleLoanData(payload: any) {
//   return (dispatch: any) => {
//     dispatch(setLoanBundleData(payload));
//   };
// }

export function getAllChatCount(bundleId: string, dispatch: any) {
  const getCount = async () => {
    const chatDetails: any = await getChatDetails(bundleId);
    if (!chatDetails.length) return;
    const chatidsObj: any = {};
    const chatLen: number = chatDetails.length - 1;
    chatDetails.forEach((item: any, index: number) => {
      chatidsObj[item.id] = item.secondaryIdentifierValue;
      if (index === chatLen) dispatch(getChatCount(chatidsObj));
    });
  };
  return getCount();
}

export function getChatDetails(bundleId: string, loanId: string = "") {
  let url: string = `${
    getConfig().apiUrl
  }/chatservice/BUNDLE/${bundleId}/chats`;
  if (loanId) {
    url += `?secondaryIdentifierName=loan&secondaryIdentifierValue=${loanId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        resolve([]);
      });
  });
}

export function getChatCount(chatidsObj: any[]) {
  return async (dispatch: any) => {
    const chatIds: string[] = Object.keys(chatidsObj);
    // dispatch({
    //   type: SET_BUNDLE_CHAT_COUNT,
    //   payload: await getRUChatCount(chatidsObj, chatIds)
    // });
    // dispatch({
    //   type: SET_BUNDLE_CHAT_UREAD_COUNT,
    //   payload: await getUChatCount(chatidsObj, chatIds)
    // });
    const chatCount = await getRUChatCount(chatidsObj, chatIds);
    const unreadChatCount = await getUChatCount(chatidsObj, chatIds);

    dispatch(setBundleChatCount(chatCount));
    dispatch(setBundleChatUnreadCount(unreadChatCount));
  };
}

export function updateChatReadStatus(chatId: string) {
  const url: string = `${getConfig().apiUrl}/chatservice/${chatId}/users`;
  const userId: string = getCookie("person_id") || "";
  const reqBody: any = {
    partyId: userId,
    lastReadOn: yyyymmdd(new Date())
  };
  return publicClient.post(url, reqBody);
}

export function exportSettlementTapes(bundleId: string, fileType: string) {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/export?fileType=${fileType}`;
  return publicClient.post(url);
}

export function exportLoanDetails(reqBody: any) {
  const url: string = `${getConfig().apiUrl}/aggregate/export/loans`;
  return publicClient.post(url, reqBody);
}

export function exportSettlementTapesZip(
  bundleId: string,
  fileTypes: string[]
) {
  const fileTypesString: string = fileTypes
    .map((type: string) => `fileTypes=${type}`)
    .join("&");
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/zip?${fileTypesString}`;
  return publicClient.post(url);
}

export function exportSamplePATemplate(bundleId: string) {
  // const url: string = `${getConfig().apiUrl}/pdf/build`;
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/purchaseAdvice`;
  return publicClient.get(url, { responseType: "blob" });
}

export function downloadExportedTape(uri: string, name: string) {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // eslint-disable-next-line no-delete-var
  // delete link;
}

// export function setPartyIdObj(newObj: any) {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_PARTY_ID_OBJ,
//       payload: newObj
//     });
//   };
// }

export function addContitionalOrTerms(
  bundleId: string,
  comment: string,
  name: string
) {
  const url: string = `${
    getConfig().apiUrl
  }/bundles/${bundleId}/bundleLandmarks`;
  const reqBody: any = {
    comments: comment,
    name
  };
  return new Promise((resolve) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => resolve(res))
      .catch((err: any) => {
        console.error(err);
        resolve("ERROR");
      });
  });
}

export function getDocsByTag(
  tagCode: string,
  pIden: string,
  pIdenVal: string,
  sIden: string = "",
  sIdenVal: string = ""
) {
  let url: string = `${
    getConfig().apiUrl
  }/documentdetails/${pIden}/${pIdenVal}/documents?`;
  if (sIden && sIdenVal)
    url = `${url}secondary_identifier_name=${sIden}&secondary_identifier_value=${sIdenVal}&`;
  url = `${url}tagCodes=${tagCode}`;
  return publicClient.get(url);
}

export function getFilterOptions(dataObj: any) {
  const { bundleId, fieldKey } = dataObj;
  const isLAT: boolean = isLATUser();
  const isLATTreasurer: boolean = isRole(UserRole.LAT_TREASURER);
  // "Cut off date Loan Amount", // no filter
  // "Cut off Date Maximum Loan Amount", // no filter
  // "Financed Budget Amount", // no filter
  // "First Loss", // no filter
  // "First Payment Date", // no filter
  // "Loan Structure" // no filter
  const fieldKeySan: string = String(fieldKey).replace(/\?/g, "");
  const mapKey: string = filterKeyMapping[fieldKeySan].key;
  const mapVal: string =
    filterKeyMapping[fieldKeySan].type === "string"
      ? `${fieldKeySan}.raw`
      : fieldKeySan;
  const reqBody: any = {
    // "script_fields": {
    //   "loanDynamicStatus": {
    //     "script": {
    //       "source": "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
    //     }
    //   }
    // },
    query: {
      bool: {
        must: [
          {
            term: {
              "bundle.bundleId.raw": {
                value: bundleId
              }
            }
          }
        ]
      }
    },
    aggs: {
      [mapKey]: {
        terms: {
          field: mapVal,
          size: 50
        }
      }
    }
  };
  if (!isLAT && !isLATTreasurer) {
    // eslint-disable-next-line dot-notation
    reqBody.query.bool.must.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id") || ""]
      }
    });
  }
  const config = getConfig();
  const url = `${config.apiUrl}/search/${config.prefix}_loan_aggregate/_search`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        resolve({});
      });
  });
}

export const filterTrigger = (activeBundle: any, selectedFilterObjs: any) => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const isLAT: boolean = isLATUser();
    const config = getConfig();
    // const url = `${config.apiUrl}/aggregate/bundles/${activeBundle.bundleId}/loans/fetch`;
    const url = `${config.apiUrl}/search/${config.prefix}_loan_aggregate/_search`;
    const must: any = [
      {
        term: {
          "bundle.bundleId.raw": {
            value: activeBundle.bundleId
          }
        }
      }
    ];
    if (!isLAT && !isRole(UserRole.LAT_TREASURER)) {
      must.push({
        terms: {
          "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id") || ""]
        }
      });
    }
    Object.entries(selectedFilterObjs).forEach(([key, value]: [any, any]) => {
      if (key !== "filterInitiated") {
        must.push({
          terms: {
            [filterKeyMapping[key].type === "string" ? `${key}.raw` : key]:
              value
          }
        });
      }
    });
    const reqBody: any = {
      query: {
        bool: {
          must
        }
      },
      _source: ["*"],
      sort: [{ "loan.loanDetailId.loanId.raw": "asc" }, "_score"],
      // size: 50,
      from: 0
    };
    // const reqBody: any = {
    //   projections: ["*"],
    //   sort: [{ "loan.loanDetailId.loanId.raw": "asc" }, "_score"],
    //   partyIds:
    //     !isLAT && !isRole(UserRole.LAT_TREASURER)
    //       ? [getCookie("org_id") || ""]
    //       : [],
    //   limit: 0,
    //   offest: 0
    // };
    const response: any = await new Promise((resolve, reject) => {
      publicClient
        .post(url, reqBody)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve({
            data: {
              response: {
                hits: {
                  hits: []
                }
              }
            }
          });
        });
    });
    const { hits } = response.data.response.hits;
    const bundleloansDetailsObj: any = {};
    let bundleLoanDetailsCopy: any = [];
    if (activeBundle?.bundleLoanDetails_copy?.length) {
      bundleLoanDetailsCopy = activeBundle.bundleLoanDetails_copy;
      activeBundle.bundleLoanDetails_copy.forEach((item: any) => {
        bundleloansDetailsObj[item._id] = item;
      });
    } else {
      bundleLoanDetailsCopy = activeBundle.bundleLoanDetails;
      activeBundle.bundleLoanDetails.forEach((item: any) => {
        bundleloansDetailsObj[item._id] = item;
      });
    }
    try {
      // dispatch({
      //   type: ACTIVE_BUNDLE,
      //   payload: {
      //     ...activeBundle,
      //     bundleLoanDetails: hits.map((item: any) => {
      //       return bundleloansDetailsObj[item._id];
      //     }),

      //     bundleLoanDetails_copy: bundleLoanDetailsCopy
      //   }
      // });
      dispatch(
        activeBundle({
          ...activeBundle,
          bundleLoanDetails: hits.map(
            (item: any) => bundleloansDetailsObj[item._id]
          ),
          bundleLoanDetails_copy: bundleLoanDetailsCopy
        })
      );
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      console.error(err, "filtering");
    }
  };
};

export function getDocumentsofMultipleIdentifiers(
  idenListObj: any[],
  tag: string
) {
  const url: string = `${getConfig().apiUrl}/documentdetails/fetch?tags=${tag}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, idenListObj)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        resolve({});
      });
  });
}

export const updateBundleLoanFields = (
  bundleId: string,
  loanIds: any[],
  payload: any
) => {
  const qryStr: string = loanIds
    .map((loan: string) => `loanIds=${loan}`)
    .join("&");
  const url: string = `${
    getConfig().apiUrl
  }/bundles/${bundleId}/loans?${qryStr}`;
  return publicClient.patch(url, payload);
};

export const callLoanRulesSynchronously = (
  loanId: string,
  loanStage: string,
  payload: any
) => {
  // let loanRuleType: string = "";
  // if (loanType === "Bridge") loanRuleType = "bridge";
  // else if (loanType === "30 Year Loan") loanRuleType = "30year";
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/evaluation/loan/${loanId}/results?loanStage=${loanStage}&resultType=OVERRIDDEN&syncRules=true&syncSettlement=true`;
  return publicClient.post(url, payload);
};

export const getGrades = (loanId: string, loanStage: string) => {
  const url: string = `${
    getConfig().apiUrl
  }/exceptionservice/${loanId}/grades?loanStage=${loanStage}`;
  return new Promise((resolve) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch((err: any) => resolve({}));
  });
};

export const settlementTabPolling = (bundleId: string) => {
  const url: string = `${getConfig().apiUrl}/bundles/${bundleId}/sync-status`;
  return new Promise((resolve) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => resolve({}));
  });
};

export const getESBundleResult = (bundleId: string) => {
  const reqBody: any = {
    projections: ["*"],
    sort: [
      {
        "loan.loanDetailId.loanId.raw": "asc"
      },
      "_score"
    ],
    partyIds:
      !isLATUser() && !isRole(UserRole.LAT_TREASURER)
        ? [getCookie("org_id") || ""]
        : [],
    limit: 0,
    offest: 0
  };
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/loans/fetch`;
  return publicClient.post(url, reqBody);
};

export const removeSearched = (dispatch: any) => {
  const cb = () => {
    // dispatch({
    //   type: EMPTY_SEARCH_LOANID,
    //   payload: true
    // });
    // dispatch({
    //   type: SET_SEARCHED_LOANID,
    //   payload: ""
    // });
    // dispatch({
    //   type: SET_VIEW_BUNDLE,
    //   payload: ""
    // });
    dispatch(emptySearchLoanID(true));
    dispatch(setSearchedLoanID(""));
    dispatch(setViewBundle(""));
  };
  cb();
};

// export const setEditHistory = (payload: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_EDIT_HISTORY,
//       payload
//     });
//   };
// };

export const getBundlesEditHistory = (bundleId: string, dispatch: any) => {
  const cb = () => {
    const url: string = `${
      getConfig().apiUrl
    }/aggregate/auditservice/bundles/${bundleId}`;
    publicClient
      .get(url)
      .then((res: any) => {
        if (res?.data?.audit) {
          dispatch(setEditHistory(res.data));
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  cb();
};

export const postBundlesEditHistory = (
  bundleId: string,
  payload: any,
  dispatch?: any
) => {
  const callBack = () => {
    const url: string = `${getConfig().apiUrl}/audit/add/bundles/${bundleId}`;
    publicClient
      .post(url, payload)
      .then((res: any) => {
        if (dispatch) getBundlesEditHistory(bundleId, dispatch);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  callBack();
};

export const postBundlesEditHistoryV2 = (bundleId: string, payload: any) => {
  const url: string = `${getConfig().apiUrl}/audit/add/bundles/${bundleId}`;
  return publicClient.post(url, payload);
};

export const postLoanEditHistory = (loanId: string, payload: any) => {
  const url: string = `${getConfig().apiUrl}/audit/add/loans/${loanId}`;
  return new Promise((resolve) => {
    publicClient
      .post(url, payload)
      .then((_res) => {
        resolve({});
      })
      .catch((err: any) => {
        console.error(err);
        resolve({});
      });
  });
};

export const signPurchaseAdvise = (payload: DocuSignReqBody) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/docusign/purchaseadvise`;
  return publicClient.post(url, payload);
};

export const getMLPAdate = (payload: { userId: string }) => {
  const url: string = `${
    getConfig().apiUrl
  }/custom/ORIGINATOR_MLPA_DATE/value/fetch`;
  return publicClient.post(url, payload);
};

export const updateMLPAdate = (payload: {
  originatorId: string;
  mlpaDate: string;
}) => {
  const url: string = `${getConfig().apiUrl}/aggregate/custom/MLPA_DATE/value`;
  return new Promise((resolve) => {
    publicClient
      .post(url, payload)
      .then((res: any) => resolve(res))
      .catch((err: any) => {
        console.error(err);
        resolve("ERROR");
      });
  });
};

// export const setOrgFilter = (val: any[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: GET_FILTER_ORG_LIST,
//       payload: val
//     });
//   };
// };

// export const setBundleDateRange = (val: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_BUNDLE_DATE_RANGE,
//       payload: val
//     });
//   };
// };

// export const clearBundleData = () => {
//   return (dispatch: any) => {
//     dispatch({
//       type: CLEAR_BUNDLE_DATA
//     });
//   };
// };

const isWhlMappedToSeller = (
  whlToSellerMap: ObjectType,
  sellerPartyId: string,
  isTableFunding: boolean,
  warehouseLenderPartyId: string
) => {
  const whl = whlToSellerMap[warehouseLenderPartyId] || [];
  return (
    !warehouseLenderPartyId ||
    isTableFunding ||
    (warehouseLenderPartyId && whl.includes(sellerPartyId))
  );
};

const getGuarntorInfo = (guarantors: any[], loanUserMap: any) => {
  let foreignNational: any = "";
  let guarantor1FirstName: any = "";
  let guarantor1LastName: any = "";
  let additionalGuarantor: any = "";
  guarantors.forEach((guarantorObj: any) => {
    if (loanUserMap?.[guarantorObj.partyId]?.isPrimary) {
      foreignNational = guarantorObj["customer"].foreignNationalString;
      guarantor1FirstName = guarantorObj["customer"].firstName;
      guarantor1LastName = guarantorObj["customer"].lastName;
    } else {
      additionalGuarantor = `${guarantorObj["customer"].firstName} ${guarantorObj["customer"].lastName}`;
    }
  });
  return {
    foreignNational,
    guarantor1FirstName,
    guarantor1LastName,
    additionalGuarantor
  };
};

export const getBorrowerInfo = (
  borrowers: any[],
  loanUserMap: any,
  isGroundUp?: boolean
) => {
  let bAccs: any = [];
  const bUser: any = {};
  borrowers.forEach((borrower: any) => {
    const bObj = borrower.customer;
    if (bObj.partyType === "account") {
      const bAcc: any = {};
      bAcc.borrowerName = bObj.accountName;
      bAcc.borrowerExperience =
        bObj.partyId in loanUserMap
          ? isGroundUp
            ? loanUserMap[bObj.partyId]?.borrowerGUCExperience
            : loanUserMap[bObj.partyId]?.experience
          : "0";
      bAcc.userSequence = loanUserMap?.[bObj?.partyId]?.loanUserSequence;
      bAccs.push(bAcc);
    } else if (
      bObj.partyType === "person" &&
      loanUserMap[bObj.partyId].isPrimary
    ) {
      bUser.borrowerName = `${bObj.firstName} ${bObj.lastName}`;
      bUser.borrowerExperience =
        (isGroundUp
          ? loanUserMap[bObj.partyId]?.borrowerGUCExperience
          : loanUserMap[bObj.partyId].experience) || "0";
    }
  });
  // sort baccs based on borrowerExperience and if they are equeal then based on user sequence
  if (bAccs.length > 1) {
    bAccs = bAccs?.sort((a: any, b: any) => {
      if (a?.borrowerExperience === b?.borrowerExperience) {
        return a?.userSequence - b?.userSequence;
      } else {
        return b?.borrowerExperience - a?.borrowerExperience;
      }
    });
  }
  return Object.keys(bUser ?? {})?.length ? bUser : bAccs.length ? bAccs[0] : {};
};

const getPropertyInfo = (properties: any[]) => {
  let propertList: any[] = [];
  let originalAsIsAppraisalValue: number = 0;
  let originalAsRepairedAppraisedValue: number = 0;
  const originalAsIsAppraisalValueList: any[] = [];
  const originalAsRepairedAppraisedValueList: any[] = [];
  if (properties?.length) {
    propertList = properties.map((pObj: any) => {
      const f1 = `${pObj?.propertyEconomics?.originalAsIsAppraisalValue}`
        .replace("$", "")
        .replace(/,/g, "");
      originalAsIsAppraisalValue += Number(f1) || 0;
      originalAsIsAppraisalValueList.push(
        sanitizeValue(formatType.currency, f1)
      );
      const f2 = `${pObj?.propertyEconomics?.originalAsRepairedAppraisedValue}`
        .replace("$", "")
        .replace(/,/g, "");
      originalAsRepairedAppraisedValue += Number(f2) || 0;
      originalAsRepairedAppraisedValueList.push(
        sanitizeValue(formatType.currency, f2)
      );
      return {
        addressLine1: pObj?.propertyLocation?.addressLine1,
        city: pObj?.propertyLocation?.city,
        state: pObj?.propertyLocation?.state,
        postalCode: pObj?.propertyLocation?.postalCode
      };
    });
  }
  return {
    propertList,
    originalAsIsAppraisalValue: `${originalAsIsAppraisalValue}`,
    originalAsRepairedAppraisedValue: `${originalAsRepairedAppraisedValue}`,
    originalAsIsAppraisalValueList,
    originalAsRepairedAppraisedValueList
  };
};

const getBridgeColumns = (
  eLoanData: any,
  bundle: ObjectType,
  bCal: any,
  loanBObj: any,
  isLAT: boolean,
  SGen: boolean,
  partyIdsObj: any,
  whlToSellerMap: ObjectType
) => {
  const loan = eLoanData || {};
  const {
    loanEconomics,
    loanDetailId,
    loanInfo,
    loanCredit,
    userInfo,
    properties
  } = loan;
  const { loanId } = loanDetailId;
  const result: ObjectType = bCal?.data?.loanResult?.[loanId] || {};
  const loanResults: any = bCal?.data?.loanResults || {};
  const isTableFunding = bundle.fundingType === TABLE_FUNDING;
  const loanUserMapObj: any = {};
  const borrowers: ObjectType[] = [];
  const guarantors: ObjectType[] = [];
  if (userInfo?.length) {
    userInfo.forEach((itm: any) => {
      if (itm.userType === "Borrower") {
        borrowers.push(itm);
      } else if (itm.userType === "Guarantor") {
        guarantors.push(itm);
      }
      loanUserMapObj[itm.partyId] = itm;
    });
  }
  const { borrowerName, borrowerExperience } = getBorrowerInfo(
    borrowers,
    loanUserMapObj,
    loanInfo.toorakProduct === ToorakProductEnum.GroundUp
  );
  const {
    propertList,
    originalAsIsAppraisalValue,
    originalAsRepairedAppraisedValue,
    originalAsIsAppraisalValueList,
    originalAsRepairedAppraisedValueList
  } = getPropertyInfo(properties);
  const toorakLendingAmount =
    loanInfo?.loanStructure === LoanStructureTypes.multipleDraw
      ? loanEconomics?.cutOffDateLoanAmount
      : loanEconomics?.cutOffDateMaximumLoanAmount;

  const {
    additionalSellerRetainedInterest,
    warehouseLenderPartyId,
    miscFees,
    interestRateAsOfCutOffDate,
    servicerPartyId,
    sellerPartyId,
    regularCreditReserveHoldback,
    additionalCreditReserveHoldback,
    finalToorakYield,
    finalToorakYield_SFR,
    drawDetails,
    originalAsIsLtv,
    maxLoanProceedsBeforeFirstLoss,
    // regularToorakYield, // we will use from result api
    loanBundleStatus,
    isCustodyDocConfirmed,
    lessPurchaserCostAllocation,
    loanStage,
    closingDate
    // pricing // we will use from result api
  } = loanBObj[loanDetailId?.loanId] || {};

  let latestDrawDate: any = null;
  const totalDrawAmount = drawDetails?.length
    ? drawDetails
        .map((itm: any) => {
          if (!latestDrawDate) latestDrawDate = itm.drawDate;
          else if (new Date(itm.drawDate) > new Date(latestDrawDate))
            latestDrawDate = itm.drawDate;
          if (!isNaN(itm.drawAmount)) {
            return Number(itm.drawAmount);
          }
          return 0;
        })
        .reduce((a: number, b: number) => a + b, 0)
    : 0;

  if (!SGen) {
    // Pre Settlement Generation LAT and Originator Bridge columns
    return {
      loanId: loanDetailId?.loanId,
      primaryLoanId: loanInfo?.primaryLoanId,
      loanStage,
      propertiesAddrList: propertList,
      originalAsIsAppraisalValueList,
      originalAsRepairedAppraisedValueList,
      borrowerName,
      borrowerExperience,
      cutOffDateLoanAmount: loanEconomics?.cutOffDateLoanAmount,
      cutOffDateMaximumLoanAmount: loanEconomics?.cutOffDateMaximumLoanAmount,
      toorakLendingAmount,
      totalBudgetAmount: loanEconomics?.totalBudgetAmount,
      originalAsIsAppraisalValue,
      originalAsRepairedAppraisedValue,
      firstPaymentDateOfLoan: loanInfo?.firstPaymentDateOfLoan,
      loanStructure: loanInfo?.loanStructure,
      toorakYield: result?.toorakInterests?.toorakYield,
      loanType: "Bridge Loan",
      loanBundleStatus,
      isCustodyDocConfirmed,
      warehouseLenderPartyId,
      warehouseLender: partyIdsObj[warehouseLenderPartyId],
      latestDrawDate,
      totalDrawAmount,
      sellerName: partyIdsObj[sellerPartyId],
      sellerPartyId,
      isWhlMappedToSeller: isWhlMappedToSeller(
        whlToSellerMap,
        sellerPartyId,
        isTableFunding,
        warehouseLenderPartyId
      ),
      phase1ClosingDate: loanCredit?.phase1ClosingDate,
      phase1ClosingDatePayment: loanCredit?.phase1ClosingDatePayment,
      phase1HoldbackAmount: loanCredit?.phase1HoldbackAmount,
      phase1HoldbackPercentage: loanCredit?.phase1HoldbackPercentage,
      closingDate,
      firstLossPercentage: result?.firstLoss?.firstLossPercentage,
      pricingDiscount: result?.toorakInterests?.pricingDiscount,
      totalCreditReserveHoldback: result?.firstLoss?.totalCreditReserveHoldback,
      downPaymentReserve: loanEconomics?.downPaymentReserve
    };
  }
  const {
    finalServicingFee,
    fundedLoanAmount,
    closingDatePayment,
    creditReserveHoldback,
    purchasePrice,
    currentPrincipalBalance,
    // lessPurchaserCostAllocation,
    plusAccruedAndUnPaidInterest,
    sellerRetainedInterest_onlyStrip,
    // interestAccrualDate,
    accruedInterestDays,
    maximumPrincipalBalance,
    dryCreditReserveHoldback,
    remic,
    // firstDueDateAsOfPurchase,
    escrow_wireToCohen,
    originalCreditScoreMedian,
    borrowerExperienceLevel,
    tableFundingFeePercent,
    tableFundingFee,
    wireDueToOriginator,
    wireDueToEscrowAgent,
    dueToOrFromOriginator,
    interestReservesAndOthersWireToServicer,
    netWireDueToAccOrSeller
  } = loanResults[loanDetailId?.loanId]?.settlementResults || {};
  const bGGPlusOrgColumns = {
    loanId: loanDetailId?.loanId,
    primaryLoanId: loanInfo?.primaryLoanId,
    loanStage,
    sellerName: partyIdsObj[sellerPartyId],
    isWhlMappedToSeller: isWhlMappedToSeller(
      whlToSellerMap,
      sellerPartyId,
      isTableFunding,
      warehouseLenderPartyId
    ),
    interestRateAsOfCutOffDate,
    borrowerName,
    borrowerExperience,
    currentPrincipalBalance,
    lessPurchaserCostAllocation,
    plusAccruedAndUnPaidInterest,
    purchasePrice,
    creditReserveHoldback,
    closingDatePayment,
    fundedLoanAmount,
    finalServicingFee,
    sellerRetainedInterest_onlyStrip,
    accrualDate: result?.settlementResults?.accrualDate,
    accruedInterestDays,
    custodian: "US Bank",
    servicerPartyId,
    sellerPartyId,
    servicer: partyIdsObj[servicerPartyId],
    toorakYield: result?.toorakInterests?.toorakYield,
    maximumPrincipalBalance,
    regularCreditReserveHoldback,
    additionalCreditReserveHoldback,
    dryCreditReserveHoldback,
    remic,
    nextPaymentDueDate: result?.settlementResults?.nextPaymentDueDate,
    closingDate,
    miscFees,
    warehouseLenderPartyId,
    warehouseLender: partyIdsObj[warehouseLenderPartyId],
    escrow_wireToCohen,
    interestReservesAndOthersWireToServicer,
    additionalSellerRetainedInterest,
    loanType: "Bridge Loan",
    loanBundleStatus,
    isCustodyDocConfirmed,
    loanStructure: loanInfo?.loanStructure,
    originalLoanAmount: loanEconomics?.originalLoanAmount,
    toorakLendingAmount,
    tableFundingFeePercent,
    tableFundingFee,
    wireDueToOriginator,
    dueToOrFromOriginator,
    wireDueToEscrowAgent,
    propertiesAddrList: propertList,
    netWireDueToAccOrSeller,
    phase1ClosingDate: loanCredit?.phase1ClosingDate,
    phase1ClosingDatePayment: loanCredit?.phase1ClosingDatePayment,
    phase1HoldbackAmount: loanCredit?.phase1HoldbackAmount,
    phase1HoldbackPercentage: loanCredit?.phase1HoldbackPercentage,
    downPaymentReserve: loanEconomics?.downPaymentReserve
  };
  if (!isLAT) return bGGPlusOrgColumns;
  const { guarantor1FirstName, guarantor1LastName } = getGuarntorInfo(
    guarantors,
    loanUserMapObj
  );
  // seems like it is not used in UI
  // let grade: any = "";
  // if (grades?.length) {
  //   const gradesList: any[] = grades.filter(
  //     (itm: any) => itm.name === "SECURITIZATION_GRADE"
  //   );
  //   if (gradesList.length) grade = grades[0].value;
  // }
  return {
    ...bGGPlusOrgColumns,
    finalToorakYield,
    finalToorakYield_SFR,
    totalDrawAmount,
    latestDrawDate,
    drawDetails,
    financedInterestReserve: loanEconomics?.financedInterestReserve,
    noOfDraws: drawDetails?.length,
    toorakProduct: loanInfo?.toorakProduct,
    originalAsIsLtv,
    totalOriginationAndDiscountPoints:
      loanEconomics?.totalOriginationAndDiscountPoints,
    interestRateAsOfCutOffDate: loanEconomics?.interestRateAsOfCutOffDate,
    // loanStructure: loanInfo?.loanStructure,
    cutOffDateLoanAmount: loanEconomics?.cutOffDateLoanAmount,
    cutOffDateMaximumLoanAmount: loanEconomics?.cutOffDateMaximumLoanAmount,
    originationDate: loanInfo?.originationDate,
    maxLoanProceedsBeforeFirstLoss,
    guarantor1LastName,
    guarantor1FirstName,
    originalCreditScoreMedian,
    regularToorakYield: result?.toorakProceedsTest?.regularToorakYield,
    borrowerExperienceLevel,
    // grade,
    pricing: result?.toorakInterests?.pricing,
    standardFirstLoss: result?.firstLoss?.sellerCreditReserveHoldback,
    standardFirstLossAdjustment: result?.firstLoss?.standardFirstLossAdjustment,
    locationAdjustment: result?.toorakInterests?.locationAdjustment
  };
};

const getThirtyYearCoulmns = (
  eLoanData: any,
  bundle: ObjectType,
  bCal: any,
  loanBObj: any,
  isLAT: boolean,
  SGen: boolean,
  partyIdsObj: any,
  bundleStatus: BundleStatuses,
  whlToSellerMap: ObjectType
) => {
  const loan = eLoanData || {};
  const {
    loanEconomics,
    loanDetailId,
    loanInfo,
    loanCredit,
    userInfo,
    properties
  } = loan;
  const { loanId, fundingType } = loanDetailId ?? {};
  const result: ObjectType = bCal?.data?.loanResult?.[loanId] || {};
  const isTableFunding = fundingType === TABLE_FUNDING;
  const {
    propertList,
    originalAsIsAppraisalValue,
    originalAsIsAppraisalValueList
  } = getPropertyInfo(properties);
  const {
    warehouseLenderPartyId,
    miscFees,
    interestRateAsOfCutOffDate,
    servicerPartyId,
    originalAsIsLtv,
    originalInterestRate,
    loanBundleStatus,
    isCustodyDocConfirmed,
    isThirdPartyServiced,
    lessPurchaserCostAllocation,
    loanStage,
    servicingFeePerMonth,
    sellerPartyId,
    closingDate
  } = loanBObj[loanDetailId?.loanId] || {};
  if (!SGen) {
    // Pre Settlement Generation LAT and Originator 30Yr columns
    return {
      loanId: loanDetailId?.loanId,
      primaryLoanId: loanInfo?.primaryLoanId,
      loanStage,
      propertiesAddrList: propertList,
      finalToorakPrice: result?.loanPricing?.finalToorakPrice,
      firstPaymentDateOfLoan: loanInfo?.firstPaymentDateOfLoan,
      toorakProduct: loanInfo?.toorakProduct,
      loanPurpose: loanInfo?.loanPurpose,
      interestRateAsOfCutOffDate: loanEconomics?.interestRateAsOfCutOffDate,
      prePaymentPenaltyMonths: result?.loanEconomics?.prePaymentPenaltyMonths,
      interestOnlyPeriod: loanEconomics?.interestOnlyPeriod,
      dscr: result?.loanEconomics?.dscr,
      originalAsIsLtv: result?.loanFeatures?.originalAsIsLtv,
      originalLtc: result?.loanFeatures?.originalLtc,
      originalLoanAmount: loanEconomics?.originalLoanAmount,
      currentLoanBalance: result?.loanEconomics?.currentLoanBalanceAmount ?? loanEconomics?.currentLoanBalance,
      originalAsIsAppraisalValue,
      loanType: "30 Year Loan",
      loanBundleStatus,
      isCustodyDocConfirmed,
      originalAsIsAppraisalValueList,
      warehouseLenderPartyId,
      sellerName: partyIdsObj[sellerPartyId],
      sellerPartyId,
      isWhlMappedToSeller: isWhlMappedToSeller(
        whlToSellerMap,
        sellerPartyId,
        isTableFunding,
        warehouseLenderPartyId
      ),
      warehouseLender: partyIdsObj[warehouseLenderPartyId],
      phase1ClosingDate: loanCredit?.phase1ClosingDate,
      phase1ClosingDatePayment: loanCredit?.phase1ClosingDatePayment,
      phase1HoldbackAmount: loanCredit?.phase1HoldbackAmount,
      phase1HoldbackPercentage: loanCredit?.phase1HoldbackPercentage,
      closingDate,
      isThirdPartyServiced:
        bundleStatus === BundleStatuses.LDCP ? "―" : isThirdPartyServiced,
      pricing: result?.toorakInterests?.pricing,
      firstLossPercentage: result?.firstLoss?.firstLossPercentage,
      pricingDiscount: result?.toorakInterests?.pricingDiscount,
      totalCreditReserveHoldback: result?.firstLoss?.totalCreditReserveHoldback
    };
  }

  const loanResults: any = bCal?.data?.loanResults || {};
  const {
    fundedLoanAmount,
    closingDatePayment,
    purchasePrice,
    currentPrincipalBalance,
    plusAccruedAndUnPaidInterest,
    sellerRetainedInterest_onlyStrip,
    accruedInterestDays,
    remic,
    originalCreditScoreMedian,
    purchasePremium,
    taxEscrowWireToServicer,
    insuranceEscrowWireToServicer,
    tableFundingFeePercent,
    tableFundingFee,
    wireDueToOriginator,
    wireDueToEscrowAgent,
    dueToOrFromOriginator,
    interestReservesAndOthersWireToServicer,
    netWireDueToAccOrSeller
  } = loanResults[loanDetailId.loanId]?.settlementResults || {};
  const loanUserMapObj: any = {};
  const borrowers: ObjectType[] = [];
  const guarantors: ObjectType[] = [];
  if (userInfo?.length) {
    userInfo.forEach((itm: any) => {
      if (itm.userType === "Borrower") {
        borrowers.push(itm);
      } else if (itm.userType === "Guarantor") {
        guarantors.push(itm);
      }
      loanUserMapObj[itm.partyId] = itm;
    });
  }
  const { guarantor1FirstName, guarantor1LastName } = getGuarntorInfo(
    guarantors,
    loanUserMapObj
  );
  const { borrowerName, borrowerExperience } = getBorrowerInfo(
    borrowers,
    loanUserMapObj
  );
  const DSCRGPlusOrgColumns = {
    loanId: loanDetailId.loanId,
    primaryLoanId: loanInfo.primaryLoanId,
    loanStage,
    sellerName: partyIdsObj[sellerPartyId],
    sellerPartyId,
    isWhlMappedToSeller: isWhlMappedToSeller(
      whlToSellerMap,
      sellerPartyId,
      isTableFunding,
      warehouseLenderPartyId
    ),
    interestRateAsOfCutOffDate,
    borrowerName,
    currentPrincipalBalance,
    lessPurchaserCostAllocation,
    plusAccruedAndUnPaidInterest,
    purchasePrice,
    purchasePremium,
    closingDatePayment,
    fundedLoanAmount,
    servicingFeePerMonth,
    sellerRetainedInterest_onlyStrip,
    accrualDate: result?.settlementResults?.accrualDate,
    accruedInterestDays,
    custodian: "US Bank",
    servicerPartyId,
    servicer: partyIdsObj[servicerPartyId],
    finalToorakPrice: result?.loanPricing?.finalToorakPrice,
    remic,
    nextPaymentDueDate: result?.settlementResults?.nextPaymentDueDate,
    closingDate,
    miscFees,
    warehouseLenderPartyId,
    warehouseLender: partyIdsObj[warehouseLenderPartyId],
    taxEscrowWireToServicer,
    insuranceEscrowWireToServicer,
    interestReservesAndOthersWireToServicer,
    loanType: "30 Year Loan",
    loanBundleStatus,
    isCustodyDocConfirmed,
    tableFundingFeePercent,
    tableFundingFee,
    wireDueToOriginator,
    wireDueToEscrowAgent,
    dueToOrFromOriginator,
    propertiesAddrList: propertList,
    netWireDueToAccOrSeller,
    phase1ClosingDate: loanCredit?.phase1ClosingDate,
    phase1ClosingDatePayment: loanCredit?.phase1ClosingDatePayment,
    phase1HoldbackAmount: loanCredit?.phase1HoldbackAmount,
    phase1HoldbackPercentage: loanCredit?.phase1HoldbackPercentage
  };
  if (!isLAT) return DSCRGPlusOrgColumns;
  // seems like it is not used in UI
  // let grade: any = "";
  // if (grades?.length) {
  //   const gradesList: any[] = grades.filter(
  //     (itm: any) => itm.name === "SECURITIZATION_GRADE"
  //   );
  //   if (gradesList.length) grade = grades[0].value;
  // }
  return {
    ...DSCRGPlusOrgColumns,
    toorakProduct: loanInfo?.toorakProduct,
    originalAsIsLtv,
    totalOriginationAndDiscountPoints:
      loanEconomics?.totalOriginationAndDiscountPoints,
    borrowerExperience,
    originalInterestRate,
    currentLoanBalance: result?.loanEconomics?.currentLoanBalanceAmount ?? loanEconomics?.currentLoanBalance,
    originationDate: loanInfo?.originationDate,
    guarantor1LastName,
    guarantor1FirstName,
    originalCreditScoreMedian,
    updatedTaxEscrowBalance: result?.loanEconomics?.updatedTaxEscrowBalance 
      ?? loanDetailId?.loanSummaryId?.updatedTaxEscrowBalance,
    updatedInsuranceEscrowBalance: result?.loanEconomics?.updatedInsuranceEscrowBalance 
      ?? loanDetailId?.loanSummaryId?.updatedInsuranceEscrowBalance,
    escrowTaxes: loanDetailId?.loanSummaryId?.escrowTaxes,
    escrowInsurance: loanDetailId?.loanSummaryId?.escrowInsurance,
    // grade,
    financedInterestReserve: loanEconomics?.financedInterestReserve
  };
};

export const getAggBundleData = (
  bundleInfo: {
    bundleId: string;
    bundleStatus: BundleStatuses;
    loanType: string;
    originatorPartyId: any;
    loansList: any[];
    loans: any[];
    loanStage: string;
    takeoutPartner: string | null | undefined;
    closingDate: string;
    bundle: ObjectType;
  },
  isLAT: boolean
) => {
  const {
    bundleId,
    bundleStatus,
    loanType,
    originatorPartyId,
    // loansList,
    loans,
    loanStage,
    takeoutPartner,
    closingDate,
    bundle
  } = bundleInfo;
  const promiseList: any = [];
  const statusIndex: number = staticBundleStatusList.indexOf(bundleStatus);
  const SGenPlus: boolean = isLAT ? statusIndex >= 2 : statusIndex > 2;
  // promiseList.push(getBundleData(bundleId));
  promiseList.push(
    getWareHouseLenderOptions({
      loanType,
      userId: originatorPartyId
    })
  );
  promiseList.push(
    getSellersOrg({
      userId: originatorPartyId
    })
  );
  promiseList.push(getCalculetedFields(bundleId, takeoutPartner));
  if (SGenPlus) {
    const auditPayload: AuditResourceObj[] = loans.map((itm: any) => {
      return {
        resourceType: "loans",
        resourceId: `${itm.loanId}_${loanStage}`
      };
    });
    promiseList.push(
      getAuditInfoLightWeight({
        resources: [
          { resourceType: "bundles", resourceId: bundleId },
          ...auditPayload
        ]
      })
    );
    promiseList.push(
      getServicerOptions({
        loanType,
        userId: originatorPartyId
      })
    );
    if (bundleStatus === BundleStatuses.SCP && !isLAT) {
      const reqObj: any = [
        {
          identifierName: PRIMARY_IDENTIFIER,
          identifierValue: bundleId,
          secondaryIdentifierName: PAconstants.secondaryIden,
          secondaryIdentifierValue: PAconstants.secondaryIdenVal
        }
      ];
      promiseList.push(
        getDocumentsofMultipleIdentifiers(reqObj, PAconstants.tagKey)
      );
    }
  }
  return new Promise((resolve) => {
    Promise.all(promiseList)
      .then(([whlData, sellerData, bCal, auditDataa, servicerData]: any) => {
        const hits = loans;
        const loanBObj: any = {};
        let whlToSellerMap: ObjectType = {};
        if (loans.length) {
          loans.forEach((itm: any) => {
            loanBObj[itm.loanId] = { ...itm, loanStage, closingDate };
          });
        }
        const partyIdsObj: any = {};
        let wareHouseLenderList: any[] = [];
        let servicerList: any[] = [];
        if (servicerData?.data?.length) {
          servicerList = servicerData.data;
          servicerList.push({
            accountName: "Reset",
            partyId: "Reset"
          });
          servicerData.data.forEach((item: any) => {
            partyIdsObj[item.partyId] = item.accountName;
          });
        }
        if (whlData?.data?.length) {
          wareHouseLenderList = whlData.data;
          wareHouseLenderList.push({
            accountName: "Reset",
            partyId: "Reset"
          });
          whlData.data.forEach((item: any) => {
            partyIdsObj[item.partyId] = item?.accountName;
          });
        }
        if (sellerData?.data?.length) {
          sellerData.data.forEach((seller: any) => {
            partyIdsObj[seller.partyId] = seller.accountName;
            seller.warehouseLenders.forEach((whl: ObjectType) => {
              whlToSellerMap = {
                ...whlToSellerMap,
                [whl.partyId]: [
                  ...(whlToSellerMap?.[whl.partyId] ?? []),
                  seller.partyId
                ]
              };
            });
          });
        }
        const auditObj: any = {};
        const { data: auditData } = auditDataa || {};
        if (auditData && Object.keys(auditData).length) {
          auditObj[bundleId] = auditData[`bundles_${bundleId}`];
          loans.forEach((itm: any) => {
            auditObj[itm.loanId] =
              auditData[`loans_${itm.loanId}_${loanStage}`];
          });
        }
        const rowData =
          loanType === "Bridge Loan"
            ? hits.map((hObj: any) =>
                getBridgeColumns(
                  hObj,
                  bundle,
                  bCal,
                  loanBObj,
                  isLAT,
                  SGenPlus,
                  partyIdsObj,
                  whlToSellerMap
                )
              )
            : hits.map((hObj: any) =>
                getThirtyYearCoulmns(
                  hObj,
                  bundle,
                  bCal,
                  loanBObj,
                  isLAT,
                  SGenPlus,
                  partyIdsObj,
                  bundleStatus,
                  whlToSellerMap
                )
              );
              console.log("rowData", rowData)
        resolve({
          rowData,
          partyIdsObj,
          wareHouseLenderList,
          servicerList,
          auditObj,
          whlToSellerMap
        });
      })
      .catch((err) => {
        console.error(err);
        resolve({});
      });
  });
};

export const getUsersBasedOnRole = (accId: string, roleName: string) => {
  const url: any = `${
    getConfig().apiUrl
  }/aggregate/access/account/${accId}/access?roleNames=${roleName}`;
  return publicClient.get(url);
};

export const saveFilterChanges = (
  partyId: string,
  pageId: string,
  viewId: string,
  tabId: string,
  filterName: string,
  filterData: any
) => {
  const reqBody: any = {
    partyId,
    page: pageId,
    view: viewId,
    tab: tabId,
    filterName,
    isDefault: true,
    filter: filterData,
    sort: {}
  };
  const url = `${
    getConfig().apiUrl
  }/prefservice/${partyId}/page/${pageId}/view/${viewId}/tab/${tabId}/filters`;
  return publicClient.post(url, reqBody);
};

interface ExtractProps {
  originatorPartyId: string;
  bundleId: string;
  cloudFilePath: {
    BucketName: string;
    Bucketkey: string;
  };
}

export const extractDrawData = (reqBody: ExtractProps) => {
  const url: any = `${getConfig().apiUrl}/aggregate/extract/DrawData`;
  return new Promise((resolve) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((_err: any) => {
        resolve({});
      });
  });
};

export interface SaveDrawDetailsBulk {
  loans: {
    loanId: string;
    drawDetails: {
      drawAmount: string;
      drawDate: string;
    }[];
  }[];
}

export const saveDrawDetailsBulk = (
  bundleId: string,
  payload: SaveDrawDetailsBulk,
  saveType: "append" | "replace" = "append"
) => {
  const url: any = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/bulk/loans/drawDetails?action=${saveType}`;
  return publicClient.post(url, payload);
};

export interface SaveDrawDetailsBulkV2 {
  loans: {
    loanId: string;
    auditComments: string;
    drawDetails: {
      drawId?: number;
      drawAmount?: string;
      drawDate?: string;
      action: "insert" | "update" | "delete";
    }[];
  }[];
}

export const saveDrawDetailsBulkV2 = (
  bundleId: string,
  reqBody: SaveDrawDetailsBulkV2
) => {
  const url: any = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/bulk/loans/drawDetails`;
  return publicClient.post(url, reqBody);
};

export const multiLoanEditSaveAndRegenarate = (
  bundleId: string,
  editedData: any,
  loanType: any,
  loanStage: string,
  partyIdInfo: any,
  commonComment: string,
  successCB: Function,
  failureCB: Function,
  takeoutPartner?: string
) => {
  const promiseObj = new Promise((resolve) => {
    const loanTypeF: string =
      loanType === LoanTypes.BridgeLoan ? "bridge" : "30year";
    const nonRuleFieldsList: any[] = [];
    const overridenResultsObj: any = {};
    const loanBulkSave: any[] = [];
    const loanIds: any[] = Object.keys(editedData);

    loanIds.forEach((loanId: any, inx: number) => {
      const payloadInfo: any = createSSReGenPayload(
        loanId,
        editedData[loanId],
        partyIdInfo
      );
      if (
        payloadInfo?.ssNonRuleFields &&
        Object.keys(payloadInfo.ssNonRuleFields).length
      ) {
        nonRuleFieldsList.push({ loanId, ...payloadInfo.ssNonRuleFields });
      }
      if (
        payloadInfo?.toBeSS_Overriden &&
        Object.keys(payloadInfo.toBeSS_Overriden).length
      ) {
        overridenResultsObj[loanId] = {
          settlementResults: payloadInfo.toBeSS_Overriden
        };
      }
      const aggloanBulkPayload: any = {};
      if (
        payloadInfo?.ssLoanFieldsPayload &&
        Object.keys(payloadInfo.ssLoanFieldsPayload).length
      ) {
        aggloanBulkPayload.loan = payloadInfo.ssLoanFieldsPayload;
      }
      if (
        payloadInfo?.ssRulesFieldsPayload &&
        Object.keys(payloadInfo.ssRulesFieldsPayload).length
      ) {
        aggloanBulkPayload.result = payloadInfo.ssRulesFieldsPayload;
      }
      if (Object.keys(aggloanBulkPayload).length) {
        loanBulkSave.push({
          loanId,
          loanStage,
          loanType: loanTypeF,
          resultType: "OVERRIDDEN",
          syncRules: true,
          bundleId,
          bundleResultType: "OVERRIDDEN",
          bundleLoanStage: "APPROVED",
          comments: commonComment,
          partnerId: takeoutPartner,
          syncSettlement: true,
          ...aggloanBulkPayload
        });
      }
      if (inx === loanIds.length - 1) {
        resolve({
          nonRuleFieldsList,
          overridenResultsObj,
          loanBulkSave
        });
      }
    });
  });
  return promiseObj.then(async (resData: any) => {
    const { nonRuleFieldsList, overridenResultsObj, loanBulkSave } = resData;
    if (nonRuleFieldsList.length) {
      await updateSettlementFields(bundleId, {
        loans: nonRuleFieldsList
      });
    }
    if (Object.keys(overridenResultsObj).length) {
      await addOverriddenFields(bundleId, overridenResultsObj, takeoutPartner);
    }
    if (loanBulkSave.length) {
      await postLoanFieldsAggBulk(loanBulkSave, true)
        .then((res2: any) => {
          successCB();
        })
        .catch((err2: any) => {
          console.error(err2);
          failureCB();
        });
    } else {
      // generate settlement with edited fields in overriden object
      await generateSettlement(bundleId, true)
        .then((res2: any) => {
          successCB();
        })
        .catch((err2: any) => {
          console.error(err2);
          failureCB();
        });
    }
  });
};

export const postBulkLoanAudit = (payload: any) => {
  const url = `${getConfig().apiUrl}/audit/bulk`;
  return publicClient.post(url, payload);
};

export const downloadSampleTemplate = (type: "draw" | "accrual") => {
  const body = {
    Bucketkey: `drawAccrualTemplates/${type}Template.xlsx`
  };

  downloadFile(body, `${type}Template.xlsx`)
    .then((data) => console.log(data))
    .catch((error) => console.error(error));
};

export const getChatIdsInBulk = (
  payload: {
    identifierName: string;
    identifierValue: string;
    secondaryIdentifierName?: string;
    secondaryIdentifierValue?: string;
  }[]
) => {
  const url = `${
    getConfig().apiUrl
  }/chatservice/chats/fetch?includeChatLines=false&includeTotalLinesCount=false`;
  return publicClient.post(url, payload);
};

export const postChatlinesInBulk = (payload: any[]) => {
  const url = `${getConfig().apiUrl}/chatservice/chats/lines`;
  return publicClient.post(url, payload);
};

export const notifyDisagreeSC = (
  bundleId: string,
  orgId: string,
  comments: string
) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/loans/disagreeConfirmation`;
  return publicClient.post(url, {
    userId: orgId,
    comments
  });
};

export const getSellersOrg = (payload: any) => {
  return new Promise((resolve) => {
    getSigneeUsers(payload.userId).then((resp: any) => {
      const sellerData: ObjectType[] = [];
      const { customers = [], accountmap = {} } = resp.data;
      const sellersMap: Map<string, ObjectType> = new Map(
        accountmap.sellers.map((seller: ObjectType) => [seller.partyId, seller])
      );
      customers.forEach((customer: ObjectType) => {
        if (sellersMap.has(customer.partyId)) {
          const warehouseLenders =
            sellersMap.get(customer.partyId)?.warehouseLenders ?? [];
          sellerData.push({ ...customer, warehouseLenders });
        }
      });
      resolve({ data: sellerData });
    });
  });
};

export const sendSettlementForConfirmation = (
  bundleId: string,
  payload: {
    bundleStatus?: string;
    bundleSellerMapping: BundleSellerMappingReq[];
  }
) => {
  return updateBundleFields(bundleId, payload);
};

export const downloadAllPA = (bundleId: string) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/purchaseAdvice/zip`;
  return publicClient.get(url);
};

export const exportPAbySeller = (bundleId: string, sellerPartyId: string) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/purchaseAdvice?sellerPartyId=${sellerPartyId}&outputType=presignedURL`;
  return publicClient.get(url);
};

export const addNewSignee = (newSigneeData: {
  fullName: string;
  title: string;
  primaryEmail: string;
}) => {
  const payload = {
    addressList: [],
    contactList: [],
    partyAttributes: {
      title: newSigneeData.title
    },
    dateOfBirth: null,
    middleName: null,
    isForeignNational: null,
    isTestParty: false,
    partyType: "person",
    firstName: newSigneeData.fullName,
    primaryEmail: newSigneeData.primaryEmail
  };
  const url1 = `${getConfig().apiUrl}/customer/person`;
  return publicClient.post(url1, payload); // will replace or add signee details
};

export const onUpdateMLPAdate = (
  bundleId: string,
  mlpaDates: MLPAPayload[]
) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/bundles/${bundleId}/bulk/sellers/mlpaDate`;
  return publicClient.patch(url, { mlpaDates });
};

export const invokeDeleteAllPA = (bundleId: string) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/${bundleId}/deletePurchaseAdvice`;
  return publicClient.delete(url);
};
