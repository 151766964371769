import React from "react";
import { Grid, FormControlLabel, Checkbox, FormGroup, TextField, Tooltip, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { EditFieldHistoryComponent } from "@toorak/tc-common-fe-sdk";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: "4px"
      }
    },
    label: {
      "& span:last-child": {
        fontSize: 12,
        fontWeight: 400
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "1px solid #cad1d7",
      fontSize: 14,
      padding: "13px 12px",
      color: "#32325d",
      alignSelf: "flex-end",
      transition: theme.transitions.create(["box-shadow"]),
      "&:focus": {
        "text-decoration-line": "none",
        border: "solid 1px #5e72e4 !important"
      }
    },
    formControl: {
      marginTop: theme.spacing(1)
    },
    selectClassesOutlined: {
      padding: "10px 14px !important",
      fontWeight: 600
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    commentBox: {
      marginTop: "20px",
      "& label": {
        width: "100%",
        "& div": {
          "& input": {
            marginBottom: "20px"
          }
        }
      }
    },
    labelRequired: {
      color: "#f75676",
      fontSize: "12px important",
      marginLeft: "4px important",
      paddingTop: 1
    },
    selectLabel: {
      border: "1px solid red",
      marginBottom: "10px !important"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32325d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      lineHeight: "16px",
      "&:hover": {
        color: "#5e72e4"
      },
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "95%",
      textOverflow: "ellipsis",
      pointerEvents: "all"
    },
    tooltip: {
      color: "#32325d",
      backgroundColor: "white",
      fontFamily: `"Open Sans", sans-serif !important`,
      fontSize: 12,
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: "5px"
    },
    arrow: {
      color: "white"
    },
    iconInfoContainer: {
      height: "16px",
      textAlign: "end",
      position: "absolute",
      right: "0",
      top: "0",
      zIndex: 1
    },
    selectEmpty: {
      marginTop: 21,
      padding: 4,
      notchedOutline: {},
      color: "#323536",
      fontSize: 14,
      "&:before": {
        borderColor: "#cad1d7"
      },
      "&:after": {
        borderColor: "#cad1d7"
      }
    },
    selectEmptyNot: {
      width: "356px",
      height: "46px",
      margin: "0 30px",
      padding: "14px 14px 13px 12px",
      borderRadius: "4px",
      border: "solid 1px #cad1d7",
      backgroundColor: "#ffffff",
      "&:before": {
        borderColor: "#cad1d7"
      },
      "&:after": {
        borderColor: "#cad1d7"
      }
    },
    labelRequiredComments: {
      color: "#f75676",
      paddingLeft: "4px",
      fontSize: "12px",
      marginTop: "5px"
    }
  })
);

export const CheckBox = (props: any) => {
  const classes = useStyles();
  const {
    label,
    value,
    onChange,
    isViewMode,
    editedLabel,
    isLATView,
    enableReason,
    onBlur,
    style,
    gridClassName,
    editedData,
    fromSettlemet = false,
    isNotInfo = false,
    showEditedTag,
    onHover,
    onReasonBlur
  } = props;
  return (
    <>
      <Grid container className={gridClassName}>
        <FormGroup>
          <div className="checkbox-grid">
            {editedData ? (
              <EditFieldHistoryComponent
                auditList={editedData}
                fromSettelement={fromSettlemet}
                isNotInfo={isNotInfo}
                showEditedTag={showEditedTag}
                onHover={onHover}
              />
            ) : null}
          </div>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                onChange={!isViewMode ? onChange : null}
                checked={!!value}
                style={{ transform: "scale(1)" }}
              />
            }
            label={label}
          />
        </FormGroup>
      </Grid>
      <Grid container>
        {(editedLabel && isLATView) || (editedLabel && enableReason) ? (
          <TextField
            InputProps={{ classes }}
            className={classes.commentBox}
            InputLabelProps={{
              style: {
                position: "relative",
                fontWeight: 600,
                fontSize: 12,
                color: "#32325d",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor: "white"
              }
            }}
            onBlur={onReasonBlur || onBlur}
            label={
              <Grid
                container
                item
                xs={12}
                sm={12}
                className={classes.labelContainer}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  container
                  style={{ display: "block" }}
                >
                  <Tooltip
                    title={`${label}`}
                    placement="right"
                    arrow
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow
                    }}
                  >
                    <span className={classes.labelInformation}>
                      Reason For Editing {label}
                    </span>
                  </Tooltip>
                  <span className={classes.labelRequiredComments}>
                    <sup>*</sup>
                  </span>
                </Grid>
              </Grid>
            }
            style={{
              flex: 1,
              width: "100%",
              marginTop: "20px !important",

              ...style
            }}
          />
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};
