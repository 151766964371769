import { TaskTracker } from "../stores/budgetReview/budgetReview.interface";
import { convertUSDToNumber } from "../utils/formatChecks";
import { taskNames } from "../stores/tasks/Tasks.action";
import { budgetReviewStatusEnum } from "../ilp/list-view/BudgetTaskManagement";

export function sortArrayByUpdatedOn(arr: TaskTracker[]): TaskTracker[] {
  if (!arr?.length) return [];
  return arr.sort((a, b) => {
    const dateA = new Date(a.updatedOn);
    const dateB = new Date(b.updatedOn);
    return dateB.getTime() - dateA.getTime();
  });
}

export function checkPropertyMapped(obj: any): boolean {
  // Check if the object has the "propertyId" field
  if (
    obj.staticData?.documentData &&
    obj.staticData.documentData.hasOwnProperty("propertyId")
  ) {
    // Check if the value of "propertyId" is not null and not undefined
    return obj.staticData?.documentData?.propertyId !== null;
  }
  return false;
}

export const transformToReduxList = (arr: any[]) => {
  return arr.map((item: any) => ({
    budgetLineItemOrder: item.budgetLineItemOrder,
    budgetLineItemId: item.budgetLineItemId,
    item: item.item,
    description: item.description,
    // budget: item.budget?.replace(/[\$,]/g, "") || item.budget, // Remove "$" and commas to keep it numeric
    budget: `${convertUSDToNumber(item.budget)}`,
    financedBudget: `${convertUSDToNumber(item.financedBudget)}`,
    // item.financedBudget?.replace(/[\$,]/g, "") || item.financedBudget, // Remove "$" and commas to keep it numeric
    commentThreadId: item.commentThreadId,
    unit: item.unit,
    comments: null // Set comments to null
  }));
};

export function isBudgetThereAndNotStarted(
  tasks: any[],
  isDSCR: boolean
): boolean {
  if (isDSCR) return false;

  const assignedTasks = tasks?.[0]?.assignedTasks || [];

  return assignedTasks.some(
    (task: any) =>
      task.taskName === taskNames.BUDGET_REVIEW &&
      [budgetReviewStatusEnum.LoanSubmitted].includes(task.taskStatus)
  );
}
