/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LoanEvaluationRequest,
  PropertyLocation,
  PropertyInformation,
  ThirtyYearLoanEvaluationRequest,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
// import { BridgeLoanData } from "../../form-components/FormComponentsProps";
import { getBridgeLoanGuarantorInformationToPass } from "../../CreateLoanHeader";
import { getBorrowerObjectForLoanSave } from "../../create-loan.action";
import { MultiplUnitInfo } from "../../create-loan.reducerHelper";
import {
  splitISODateTillT,
  yesornToBoolean,
  isEmptyValue,
  getNumFromCommaSeparatedNum,
  getNextMonthFirstDay
} from "../../../utils/formatChecks";
import {
  rentDescription,
  rentDescriptionOld,
  LeaseStatusEnum
} from "../../constants/loanCreationDropDownValues";
import { getLoanType } from "../../../config/config";
import { UpdatedPropertyEconomics } from "../../../masterView/common";

export function propertyEvalRequest(propertyDetails: any[]) {
  const propertyEval: any[] = [];
  propertyDetails.forEach(
    (item: {
      propertyId: string;
      propertyLocation: {
        errors: number | null;
        payload: PropertyLocation;
      };
      propertyInformation: {
        errors: number | null;
        payload: PropertyInformation;
      };
      propertyEconomics: {
        errors: number | null;
        payload: UpdatedPropertyEconomics;
      };
    }) => {
      try {
        const obj = {
          propertyId: item.propertyId,
          propertyEcon: {
            grossPotentialRent: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.grossPotentialRent
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            assignmentFees: Number.parseFloat(
              (
                (item.propertyEconomics.payload &&
                  item.propertyEconomics.payload?.assignmentFees) ||
                ""
              )
                ?.replace("$", "")
                .replace(/,/g, "")
            ),
            originalAsIsAppraisalValue: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.originalAppraisalValue
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            purchasePrice: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.purchasePrice
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            annualPropertyTaxes: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.annualPropertyTaxes
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            insurance: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.insurance
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            originalAsRepairedAppraisedValue: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.originalRepairedAppraisalValue
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            costBasisAfterCostIncurred: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.costBasis
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            rentalCashflowRatio: percentageToFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.rentalCashflowRatio
            ),
            annualHOAFee: Number.parseFloat(
              item.propertyEconomics.payload.annualHoaFee
                ?.replace("$", "")
                .replace(/,/g, "")
            ),
            decliningMarkets:
              item?.propertyEconomics?.payload?.decliningMarkets || "No"
          },
          propertyInfo: {
            propertyType: item.propertyInformation.payload.propertyType,
            numberOfUnits: getNumFromCommaSeparatedNum(
              item.propertyInformation.payload.numberOfUnits
            ),
            mostRecentPropertyValuationDate: item.propertyInformation.payload
              .propertyValuationDate
              ? splitISODateTillT(
                  item.propertyInformation.payload.propertyValuationDate
                )
              : null,
            propertyAcquisitionDate: item.propertyInformation.payload
              .propertyAcquisitionDate
              ? splitISODateTillT(
                  item.propertyInformation.payload.propertyAcquisitionDate
                )
              : null,
            isPropertyPurchasedLast2Years:
              item.propertyInformation.payload.isPropertyPurchasedLast2Years,
            changeInUseCase: item.propertyInformation.payload.changeInUseCase,
            preRehabSquareFootage: getNumFromCommaSeparatedNum(
              item.propertyInformation.payload.preRehabSquareFootage
            ),
            postRehabSquareFootage: getNumFromCommaSeparatedNum(
              item.propertyInformation.payload.postRehabSquareFootage
            ),
            appraisalReportedNotOrNcf: Number.parseFloat(
              item.propertyEconomics.payload &&
                item.propertyEconomics.payload.appraisalReported
                  ?.replace("$", "")
                  .replace(/,/g, "")
            ),
            percentOccupied: percentageToFloat(
              item.propertyInformation.payload.percentageOccupied
            )
          },
          propertyLocation: {
            postalCode: item.propertyLocation.payload.zipCode,
            city: item.propertyLocation.payload.city,
            state: item.propertyLocation.payload.state
          }
        };
        propertyEval.push(obj);
      } catch (error) {
        console.log(error, "error in property eval data");
      }
    }
  );
  return propertyEval;
}
export function property30YearEvalRequest(propertyDetails: any) {
  try {
    return propertyDetails.map(
      (item: {
        propertyId: string;
        propertyDefaultName: string;
        propertyLocation: {
          errors: number | null;
          payload: PropertyLocation;
        };
        propertyInformation: {
          errors: number | null;
          payload: PropertyInformation;
        };
        propertyEconomics: {
          errors: number | null;
          payload: UpdatedPropertyEconomics;
        };
        unitsInformation: {
          unitId: any;
          errors: number | null;
          payload: MultiplUnitInfo;
        }[];
        propertySummary: any;
      }) => {
        const {
          propertyEconomics,
          propertyInformation,
          propertyId,
          propertyLocation,
          unitsInformation,
          propertySummary
        } = item;
        const {
          monthlyTaxAmount,
          monthlyInsuranceAmount,
          escrowTaxes,
          escrowInsurance,
          escrowType
        } = propertySummary || {};
        const { zipCode, city, state } = propertyLocation.payload;
        const {
          originalAppraisalValue,
          purchasePrice,
          costBasis,
          annualPropertyTaxes,
          annualHazardInsurance,
          annualHoaFee,
          annualFloodInsurance,
          thirdPartyValuation,
          decliningMarkets
          // rentalCashflowRatio
        } = propertyEconomics.payload;
        const {
          propertyType,
          numberOfUnits,
          propertyValuationDate,
          propertyAcquisitionDate,
          squareFootage
          // percentageOccupied
        } = propertyInformation.payload;
        const unitsObject = unitsInformation.map((ele: any) => {
          const propertyUnitId = ele.unitId;
          const {
            leaseStatus,
            currentLeaseTerm,
            marketRentMonthly,
            inPlaceLeaseRent,
            recentLeaseRent
          } = ele.payload;
          return {
            propertyUnitId,
            leaseStatus,
            marketRentMonthly: marketRentMonthly
              ? Number.parseFloat(
                  marketRentMonthly?.replace("$", "").replace(/,/g, "")
                )
              : null,
            inPlaceLeaseRentMonthly: inPlaceLeaseRent
              ? Number.parseFloat(
                  inPlaceLeaseRent?.replace("$", "").replace(/,/g, "")
                )
              : null,
            mostRecentLeaseRentMonthly: recentLeaseRent
              ? Number.parseFloat(
                  recentLeaseRent?.replace("$", "").replace(/,/g, "")
                )
              : null,
            unitOccupancy: [
              LeaseStatusEnum.unOcupiedLongTerm,
              LeaseStatusEnum.unOccupiedShortTerm,
              rentDescriptionOld[3]
            ].includes(leaseStatus)
              ? 0
              : 1,
            currentLeaseTermMonths: Number.parseFloat(currentLeaseTerm)
          };
        });

        return {
          propertyId,
          propertySummary: {
            monthlyTaxAmount: !isEmptyValue(monthlyTaxAmount)
              ? Number.parseFloat(
                  monthlyTaxAmount?.replace("$", "").replace(/,/g, "")
                )
              : null,
            monthlyInsuranceAmount: !isEmptyValue(monthlyInsuranceAmount)
              ? Number.parseFloat(
                  monthlyInsuranceAmount?.replace("$", "").replace(/,/g, "")
                )
              : null,
            escrowTaxes: !isEmptyValue(escrowTaxes)
              ? Number.parseFloat(
                  escrowTaxes?.replace("$", "").replace(/,/g, "")
                )
              : null,
            escrowInsurance: !isEmptyValue(escrowInsurance)
              ? Number.parseFloat(
                  escrowInsurance?.replace("$", "").replace(/,/g, "")
                )
              : null,
            escrowType
          },
          propertyEcon: {
            originalAsIsAppraisalValue: Number.parseFloat(
              originalAppraisalValue?.replace("$", "").replace(/,/g, "")
            ),
            purchasePrice: Number.parseFloat(
              purchasePrice?.replace("$", "").replace(/,/g, "")
            ),
            thirdPartyValuation: Number.parseFloat(
              thirdPartyValuation?.replace("$", "").replace(/,/g, "")
            ),
            annualPropertyTaxes: Number.parseFloat(
              annualPropertyTaxes?.replace("$", "").replace(/,/g, "")
            ),
            costBasisAfterCostIncurred: Number.parseFloat(
              costBasis?.replace("$", "").replace(/,/g, "")
            ),
            // rentalCashflowRatio:Number.parseFloat(rentalCashflowRatio)
            annualHazardInsurance: Number.parseFloat(
              annualHazardInsurance?.replace("$", "").replace(/,/g, "")
            ),
            annualFloodInsurance: Number.parseFloat(
              annualFloodInsurance?.replace("$", "").replace(/,/g, "")
            ),
            annualHOAFee: Number.parseFloat(
              annualHoaFee?.replace("$", "").replace(/,/g, "")
            ),
            decliningMarkets: decliningMarkets || "No"
          },
          propertyInfo: {
            propertyType,
            numberOfUnits: getNumFromCommaSeparatedNum(numberOfUnits),
            mostRecentPropertyValuationDate: propertyValuationDate
              ? splitISODateTillT(propertyValuationDate)
              : null,
            propertyAcquisitionDate: propertyAcquisitionDate
              ? splitISODateTillT(propertyAcquisitionDate)
              : null,
            preRehabSquareFootage: getNumFromCommaSeparatedNum(squareFootage),
            postRehabSquareFootage: 0 // to be sent as zero
            // percentOccupied: percentageToFloat(percentageOccupied) //converted to calculated field
          },
          propertyLocation: {
            postalCode: zipCode,
            city,
            state
          },
          units: unitsObject
        };
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}
export function getEvaluationRequestBody(
  loanId: string,
  loanStage: LoanStage,
  data: any,
  loanEvaluationResult: { loanResult: any; propertiesResults: any },
  propertyData?: any | null,
  loanType?: string,
  loanBundleDetails?: any
): Promise<{
  evaluate: boolean;
  loanEvaluationRequest:
    | LoanEvaluationRequest
    | ThirtyYearLoanEvaluationRequest
    | null;
}> {
  return new Promise((resolve, reject) => {
    try {
      const updatedLoanUserMapObject = getBridgeLoanGuarantorInformationToPass(
        data.guarantorInformation,
        true
      );
      const borrowerObject = getBorrowerObjectForLoanSave(
        data.borrowerInformation,
        true
      );
      let primaryBorrowerObj: any = null;
      if (data.borrowerInformation) {
        primaryBorrowerObj = data.borrowerInformation.find((item: any) => {
          return item.payload.isPrimaryGuarantor === true;
        });
        if (!primaryBorrowerObj) {
          primaryBorrowerObj = data.borrowerInformation.reduce(function (
            prev: any,
            current: any
          ) {
            return prev.borrowerExperience > current.borrowerExperience
              ? prev
              : current;
          });
        }
      }
      // let primaryGuarantorObj: any = null;
      // if (data.guarantorInformation) {
      //   primaryGuarantorObj = data.guarantorInformation.find((item: any) => {
      //     return item.payload.isPrimaryGuarantor === true;
      //   });
      // }
      // const foreignNational =
      //   primaryGuarantorObj?.payload?.foreignNationalString ?? "";
      if (loanType === getLoanType[1].displayValue) {
        const propertyRequestPart = property30YearEvalRequest(propertyData);
        const {
          loanEconomics,
          loanInformation,
          loanRuleVersions,
          loanSummary
        } = data;
        const {
          monthlyTaxAmount,
          MonthlyInsuranceAmount,
          escrowType,
          escrowTaxes,
          escrowInsurance
        } = loanSummary || {};
        const {
          cashOutAmount,
          discountPoints,
          financialInterestReserve,
          interestRate,
          loanAmount,
          accrualType,
          debtServiceCoverageRatio,
          rateType,
          subordinateFinancing,
          businessPurposeOccupancy,
          prepaymentPenaltyMonths,
          prepayPenaltyType,
          interestOnlyPeriod,
          currentLoanBalance,
          borrowerLiquidity,
          armIndex,
          grossArmMargin
        } = loanEconomics;
        const {
          loanPurpose,
          originationDate,
          pricingPaymentDueDate,
          firstPaymentDate,
          maturityDate,
          selectedRecourse,
          selectedToorakProduct,
          condoEligibility,
          selectedCross,
          rateLockPeriod,
          rateLockedRateSheet,
          loanState,
          servicerName,
          servicerPartyId,
          originatorCategoryBucket,
          takeoutPartnerId,
          creditEvent,
          rateLockExtensions,
        } = loanInformation;
        const loanEvaluationRequest: any = {
          loanFact: {
            loan: {
              loanDetail: {
                originatorPartyId:
                  sessionStorage.getItem("originatorPartyIdNew") || "",
                originatorCategoryBucket: originatorCategoryBucket
              },
              loanSummary: {
                monthlyTaxAmount: !isEmptyValue(monthlyTaxAmount)
                  ? Number.parseFloat(
                      monthlyTaxAmount?.replace("$", "").replace(/,/g, "")
                    )
                  : null,
                escrowInsurance: !isEmptyValue(escrowInsurance)
                  ? Number.parseFloat(
                      escrowInsurance?.replace("$", "").replace(/,/g, "")
                    )
                  : null,
                escrowTaxes: !isEmptyValue(escrowTaxes)
                  ? Number.parseFloat(
                      escrowTaxes?.replace("$", "").replace(/,/g, "")
                    )
                  : null,
                monthlyInsuranceAmount: !isEmptyValue(MonthlyInsuranceAmount)
                  ? Number.parseFloat(
                      MonthlyInsuranceAmount?.replace("$", "").replace(/,/g, "")
                    )
                  : null,
                escrowType
              },
              loanEcon: {
                rateType,
                financedInterestReserve: Number.parseFloat(
                  financialInterestReserve?.replace("$", "").replace(/,/g, "")
                ),
                borrowerLiquidity: Number.parseFloat(
                  borrowerLiquidity?.replace("$", "").replace(/,/g, "")
                ),
                originalLoanAmount: Number.parseFloat(
                  loanAmount?.replace("$", "").replace(/,/g, "")
                ),
                subOrdinateFinancing:
                  subordinateFinancing &&
                  typeof subordinateFinancing === "string"
                    ? subordinateFinancing.charAt(0)?.toUpperCase()
                    : "",
                prePaymentPenaltyMonths: Number.parseFloat(
                  prepaymentPenaltyMonths
                ),
                prepayPenaltyType,
                businessPurposeOccupancy:
                  businessPurposeOccupancy &&
                  typeof businessPurposeOccupancy === "string"
                    ? businessPurposeOccupancy.charAt(0)?.toUpperCase()
                    : "",
                cashOutAmount: Number.parseFloat(
                  cashOutAmount?.replace("$", "").replace(/,/g, "")
                ),
                interestOnlyPeriod: Number.parseFloat(
                  interestOnlyPeriod || "0"
                ),
                accrualType,
                dscr: Number.parseFloat(debtServiceCoverageRatio),
                interestRate: percentageToFloat(interestRate, 6),
                totalOriginationAndDiscountPoints:
                  percentageToFloat(discountPoints),
                currentLoanBalance: Number.parseFloat(
                  currentLoanBalance?.replace("$", "").replace(/,/g, "")
                ),
                armIndex: Number.parseFloat(
                  armIndex?.replace("$", "")?.replace(/,/g, "") ?? "0"
                ),
                grossMarginForArm: Number.parseFloat(grossArmMargin || "0")
              },
              loanInfo: {
                loanState,
                rateLockPeriod,
                rateLockedRateSheet,
                servicerName,
                servicerPartyId,
                loanId: loanId.toString(),
                loanType: "30year",
                product: "RENTAL",
                loanStage,
                loanPurpose,
                toorakProduct: selectedToorakProduct,
                recourse: selectedRecourse,
                condoEligibility,
                pricingPaymentDueDate: splitISODateTillT(pricingPaymentDueDate),
                originationDate: splitISODateTillT(originationDate),
                crossCollaterlization: selectedCross,
                firstPaymentDateOfLoan: splitISODateTillT(firstPaymentDate),
                originalMaturityDate: splitISODateTillT(maturityDate),
                takeoutPartnerId,
                creditEvent
              },
              rateLockExtensions,
              loanUserMap: [...updatedLoanUserMapObject, ...borrowerObject],
              loanRuleVersions
            },
            properties: propertyRequestPart
          },
          ruleAttributs: {
            ruleGroup: [], // ["loancharacterization", "loanfeatures"],
            ruleField: [],
            ruleResult: []
          },
          resultObject: {}
        };
        if (loanBundleDetails && Object.keys(loanBundleDetails).length) {
          loanEvaluationRequest.loanFact.bundle = loanBundleDetails;
        }
        resolve({
          evaluate: true,
          loanEvaluationRequest
        });
      } else {
        const propertyEvalRequestBody = propertyData
          ? propertyEvalRequest(propertyData)
          : [];
        const isEvaluated =
          (loanEvaluationResult.loanResult &&
            Object.keys(loanEvaluationResult.loanResult).length > 0) ||
          (loanEvaluationResult.propertiesResults &&
            Object.keys(loanEvaluationResult.propertiesResults).length > 0);
        if (
          isEvaluated ||
          (data?.loanEconomics?.loanAmount &&
            data?.loanEconomics?.cashOutAmount &&
            data?.loanInformation?.primaryLoanID &&
            data?.loanInformation?.selectedToorakProduct &&
            data?.loanInformation?.loanPurpose)
        ) {
          const loanEvaluationRequest: any = {
            loanFact: {
              loan: {
                loanDetail: {
                  originatorPartyId:
                    sessionStorage.getItem("originatorPartyIdNew") || "",
                  originatorCategoryBucket:
                    data?.loanInformation?.originatorCategoryBucket
                },
                loanEcon: {
                  financedBudgetAmount: Number.parseFloat(
                    data.loanEconomics &&
                      data.loanEconomics.financedBudgetAmount
                        ?.replace("$", "")
                        .replace(/,/g, "")
                  ),
                  cutOffdateLoanAmount: Number.parseFloat(
                    data.loanEconomics.cutOffLoanAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  cutOffDateMaximumLoanAmount: Number.parseFloat(
                    data.loanEconomics.cutOffMaxLoanAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  totalBudgetAmount: Number.parseFloat(
                    data.loanEconomics.budgetAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  insuranceAndAmortization: Number.parseFloat(
                    data.loanEconomics.insuranceAndAmortization
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  financedInterestReserve: Number.parseFloat(
                    data.loanEconomics.financialInterestReserve
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  originalLoanAmount: Number.parseFloat(
                    data.loanEconomics.loanAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  originalMaximumLoanAmount: Number.parseFloat(
                    data.loanEconomics.maxLoanAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  cashOutAmount: Number.parseFloat(
                    data.loanEconomics.cashOutAmount
                      ?.replace("$", "")
                      .replace(/,/g, "")
                  ),
                  accrualType: data.loanEconomics.accrualType,
                  interestRate: percentageToFloat(
                    data.loanEconomics?.interestRate,
                    6
                  ),
                  totalOriginationAndDiscountPoints: percentageToFloat(
                    data.loanEconomics.discountPoints
                  ),
                  includeOutOfPocketBudgetInARLTV:
                    data.loanEconomics.includeOutOfPocketBudgetInARLTV,
                  calculateFirstLossCalculationsOnCodAmount: data?.loanEconomics?.calculateFirstLossCalculationsOnCodAmount
                },
                loanInfo: {
                  loanId: loanId.toString(),
                  loanType: "BRIDGE",
                  product: "RENTAL",
                  loanStage,
                  loanState: data.loanInformation.loanState,
                  extensionOption: data.loanInformation.extensionOption,
                  loanPurpose: data.loanInformation.loanPurpose,
                  loanStructure: data.loanInformation.selectedLoanStructure,
                  toorakProduct: data.loanInformation.selectedToorakProduct,
                  recourse: data.loanInformation.selectedRecourse,
                  pledgeOfEquity: data.loanInformation.pledgeOfEquity,
                  servicerName: data?.loanInformation?.servicerName,
                  servicerPartyId: data?.loanInformation?.servicerPartyId,
                  pricingPaymentDueDate:
                    splitISODateTillT(
                      data.loanInformation.pricingPaymentDueDate
                    ) || getNextMonthFirstDay(),
                  originationDate: splitISODateTillT(
                    data.loanInformation.originationDate
                  ),
                  firstPaymentDateOfLoan: splitISODateTillT(
                    data.loanInformation.firstPaymentDate
                  ),
                  originalMaturityDate: splitISODateTillT(
                    data.loanInformation.maturityDate
                  )
                },
                loanUserMap: [...updatedLoanUserMapObject, ...borrowerObject],
                loanRuleVersions: data.loanRuleVersions
              },
              properties: propertyEvalRequestBody || []
            },
            ruleAttributs: {
              ruleGroup: ["loancharacterization", "loanfeatures"],
              ruleField: [],
              ruleResult: []
            },
            resultObject: {}
          };
          resolve({
            evaluate: true,
            loanEvaluationRequest
          });
        } else {
          resolve({ evaluate: false, loanEvaluationRequest: null });
        }
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export function percentageToFloat(value: any, numberOfDigits: number = 4) {
  const valueToReturn = 0.0;
  try {
    if (value) {
      const temp = value.replace("%", "");
      const percentValue = Number.parseFloat(temp) / 100;
      return percentValue.toFixed(numberOfDigits);
    }
  } catch (error) {
    return valueToReturn;
  }
  return valueToReturn;
}

export function rentalCharacterizationFormula(inputs: any) {
  const {
    leaseDurationGreaterThan12M,
    leaseDurationLessThan12M,
    vacancyStatus,
    monthlyLease,
    unitCategory
  } = inputs;
  if (
    isEmptyValue(leaseDurationGreaterThan12M) &&
    isEmptyValue(vacancyStatus) &&
    isEmptyValue(monthlyLease) &&
    isEmptyValue(leaseDurationLessThan12M) &&
    isEmptyValue(unitCategory)
  ) {
    return "Please Identify Rental Status";
  }
  if (yesornToBoolean(leaseDurationGreaterThan12M)) {
    return rentDescriptionOld[0];
  }
  if (yesornToBoolean(vacancyStatus)) {
    return rentDescriptionOld[1];
  }
  if (
    yesornToBoolean(monthlyLease) ||
    yesornToBoolean(leaseDurationLessThan12M)
  ) {
    return rentDescriptionOld[2];
  }
  return rentDescriptionOld[3];
}

export function percentageOccupiedFormula(inputs: any) {
  const { leaseStatus } = inputs;
  const rentalDescription =
    rentDescription.includes(leaseStatus) ||
    rentDescriptionOld.includes(leaseStatus)
      ? leaseStatus
      : rentalCharacterizationFormula(inputs);
  if (
    [
      LeaseStatusEnum.unOcupiedLongTerm,
      LeaseStatusEnum.unOccupiedShortTerm
    ].includes(rentalDescription) ||
    rentalDescription === rentDescriptionOld[3]
  ) {
    return "0.0000%";
  }
  if (
    [
      LeaseStatusEnum.occupiedLongTerm,
      LeaseStatusEnum.occupiedShortTerm
    ].includes(rentalDescription) ||
    [
      rentDescriptionOld[0],
      rentDescriptionOld[1],
      rentDescriptionOld[2]
    ].includes(rentalDescription)
  ) {
    return "100.0000%";
  }
  return null;
}
